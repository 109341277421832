import SystemManagement from "@/views/services/system/SystemManagement.vue";
import AllOrganizations from "@/views/services/system/organizations/AllOrganizations.vue";
import BaseOrganization from "@/views/services/system/organizations/BaseOrganization.vue";
import AddOrganization from "@/views/services/system/organizations/AddOrganization.vue";
import AllUsers from "@/views/services/system/users/AllUsers.vue";
import i18n from "@/plugins/i18n";

export default {
  systemManagement: {
    path: "/system",
    name: "systemManagement",
    component: SystemManagement,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Administration"),
        },
      ],
    },
  },
  users: {
    path: "/system/users",
    name: "globalUsers",
    component: AllUsers,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Administration"),
          href: "/#/system",
        },
        {
          name: () => i18n.t("_GlobalUsers"),
          disabled: true,
        },
      ],
    },
  },
  organizations: {
    path: "/system/organizations",
    name: "organizations",
    component: AllOrganizations,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Administration"),
          href: "/#/system",
        },
        {
          name: () => i18n.t("_Organizations"),
          disabled: false,
        },
      ],
    },
  },
  addOrganization: {
    path: "/system/organizations/add",
    name: "addOrganization",
    component: AddOrganization,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Administration"),
          href: "/#/system",
        },
        {
          name: () => i18n.t("_Organizations"),
          href: "/#/system/organizations",
        },
        {
          name: () => i18n.t("_AddOrganization"),
          disabled: false,
        },
      ],
    },
  },
  baseOrganization: {
    path: "/system/organizations/edit",
    name: "baseOrganization",
    component: BaseOrganization,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Administration"),
          href: "/#/system",
        },
        {
          name: () => i18n.t("_Organizations"),
          href: "/#/system/organizations",
        },
        {
          name: () => i18n.t("_Organization"),
          disabled: false,
        },
      ],
    },
  },
};
