<template>
  <BaseContentLoader :title="$t('_Integrations')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :items="tableItems"
        :headers="tableHeaders"
        @canRemove="(item) => removeItem(item)"
        path="/dad/integrations/integration?id="
      />

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="$router.push('/dad/integrations/add')"
          color="primary"
          :disabled="disabledAddIntegration"
          data-cy="btn-add-integration"
          >{{ $t("_AddIntegration") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadIntegrations");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getIntegrationsByOrganizationId",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
    ]),
    tableItems() {
      return this.getIntegrationsByOrganizationId(
        this.getCurrentOrganization.id,
      ).map((a) => {
        var obj = Object.assign({}, a);
        obj.removable = true;
        obj.props = {
          disabled: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
        };
        return obj;
      });
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
        },
        {
          text: this.$t("_Type").toUpperCase(),
          value: "type",
          columnFilter: true,
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledAddIntegration() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("deleteIntegration", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
