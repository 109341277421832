<template>
  <v-app>
    <BaseSidebar
      :title="$t('_ManagementConsole')"
      :showDrawer="showDrawer"
      :isVisible="showSidebar"
      :items="navigationItems"
      :bottomItems="bottomItems"
    />
    <BaseHeader
      @drawer="showDrawer = !showDrawer"
      :username="getCurrentUser"
      docsUrl="https://s3.eu-central-1.amazonaws.com/docs.grovez/en/grovez.htm"
      @openPersonalSettings="$router.push('/settings')"
    >
      <template slot="left" v-if="!$route.path.includes('/system')">
        <BaseOrganizationSelect
          :value="currentOrganization"
          :items="this.getOrganizations"
        ></BaseOrganizationSelect>
      </template>
    </BaseHeader>
    <v-main app>
      <BaseContent
        v-if="!isLoading"
        :showContent="showContent"
        :breadcrumbs="breadcrumbs"
        :emptyContent="$t('_PermissionDenied')"
      />
      <BaseInformationModal
        :value="getErrorFlag"
        :title="getErrorTitle"
        :content="getErrorText"
        @acknowledged="resetError()"
      />
    </v-main>
    <BaseFooter :name="$t('_ManagementConsole')" :version="getVersion" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import BaseOrganizationSelect from "@/components/BaseOrganizationSelect";

export default {
  data() {
    return {
      showDrawer: false,
      isLoading: true,
    };
  },
  components: {
    BaseOrganizationSelect,
  },
  async mounted() {
    try {
      // Initial loading
      await this.$store.dispatch("loadVersion");
      await this.$store.dispatch("loadLocale");
      await this.$store.dispatch("loadSupportedDeviceTypes");
      await this.$store.dispatch("loadOrganizations");
      await this.$store.dispatch("loadBillingAccessToken");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getVersion",
      "getCurrentUser",
      "getErrorFlag",
      "getErrorTitle",
      "getErrorText",
      "getOrganizations",
      "getCurrentOrganization",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
      "isCurrentOrganizationsViewer",
    ]),
    currentOrganization: {
      get: function () {
        let organization = { ...this.getCurrentOrganization };
        organization.icon = "mdi-office-building";
        return organization;
      },
      set: function (newOrganization) {
        delete newOrganization.icon;
        this.$store.dispatch("updateCurrentOrganization", newOrganization);
      },
    },
    showContent() {
      var path = this.$route.path;

      // Check in general
      if (
        (this.getCurrentOrganization.id == undefined ||
          this.getCurrentOrganization.id === "") &&
        (path.includes("/dad") ||
          path.includes("/alarming") ||
          path.includes("/organization") ||
          path.includes("/datasharing") ||
          path.includes("/licences")) &&
        !path.includes("/system")
      ) {
        return false;
      }

      // Check by roles
      if (this.isSystemAdmin) return true;

      if (this.isCurrentOrganizationsAdmin) {
        return !path.includes("/system");
      }

      if (this.isCurrentOrganizationsViewer) {
        return (
          (path === "/" ||
            path.includes("/dad") ||
            path.includes("/alarming") ||
            path.includes("/organization") ||
            path.includes("/licences") ||
            path.includes("/datasharing") ||
            path.includes("/settings")) &&
          !(
            path.includes("/add") ||
            path.includes("/users") ||
            path.includes("/register") ||
            path.includes("/onboard") ||
            path.includes("/edit")
          )
        );
      }

      // No Management Console Roles
      return path === "/" || path.includes("/settings");
    },
    showSidebar() {
      return true;
    },
    navigationItems() {
      return [
        {
          text: this.$t("_DevicesAndData"),
          path: "/dad",
          isVisible:
            this.isSystemAdmin ||
            this.isCurrentOrganizationsAdmin ||
            this.isCurrentOrganizationsViewer,
        },
        {
          text: this.$t("_Alarming"),
          path: "/alarming",
          isVisible:
            this.isSystemAdmin ||
            this.isCurrentOrganizationsAdmin ||
            this.isCurrentOrganizationsViewer,
        },
        {
          text: this.$t("_Organization"),
          path: "/organization",
          isVisible:
            this.isSystemAdmin ||
            this.isCurrentOrganizationsAdmin ||
            this.isCurrentOrganizationsViewer,
        },
        {
          text: this.$t("_LicenceManagement"),
          path: "/licences",
          isVisible: this.isSystemAdmin || this.isCurrentOrganizationsAdmin,
        },
      ];
    },
    bottomItems() {
      return [
        {
          text: this.$t("_Administration"),
          path: "/system",
          isVisible: this.isSystemAdmin,
        },
        { text: this.$t("_Settings"), path: "/settings", isVisible: true },
      ];
    },
    breadcrumbs() {
      return [...this.$route.meta.breadcrumb].map((obj) => {
        obj.text = obj.name();
        return obj;
      });
    },
  },
  methods: {
    resetError() {
      this.$store.commit("setErrorFlag", false);
      this.$store.commit("setErrorTitle", "Oops, something went wrong!");
      this.$store.commit(
        "setErrorText",
        "Please try again later or contact your service admin",
      );
    },
  },
};
</script>
