<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <ExpertMode
        v-model="isExpertMode"
        @sendDefinedCommand="(command) => sendDefinedCommand(command)"
        @sendCustomCommand="(command) => sendCustomCommand(command)"
        :integration="integration"
        :definedCommands="definedCommands"
        :disabled="disabledExpertMode"
      />

      <template v-if="!isExpertMode">
        <v-form v-model="isValid" ref="form">
          <BaseSummary
            v-model="newDeviceGroup"
            :items="summaryItems"
            isEditable
          ></BaseSummary>
        </v-form>

        <v-row class="pt-8">
          <v-spacer></v-spacer>
          <v-btn
            class="mx-3"
            @click="undoChanges()"
            :disabled="disabledUndo"
            data-cy="btn-undo"
          >
            <v-icon left>mdi-undo</v-icon>
            {{ $t("_UndoChanges") }}</v-btn
          >
          <v-btn
            class="mx-3"
            @click="saveChanges()"
            :disabled="disabledSave"
            color="primary"
            >{{ $t("_SaveChanges") }}</v-btn
          >
        </v-row>
      </template>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

import {
  isDifferent,
  delay,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
import ExpertMode from "@/components/ExpertMode.vue";
import DeviceGroupsApi from "@/data/api/DeviceGroupsApi";

export default {
  components: { ExpertMode },
  data() {
    return {
      isLoading: true,
      isValid: false,
      isExpertMode: false,
      tab: undefined,
      newDeviceGroup: undefined,
    };
  },
  async mounted() {
    try {
      this.newDeviceGroup = cloneDeep(
        await this.$store.dispatch("loadDeviceGroup", this.$route.query.id),
      );
      this.newDeviceGroup.integration = cloneDeep(
        await this.$store.dispatch(
          "loadIntegration",
          this.newDeviceGroup.integrationId,
        ),
      );
      await this.$store.dispatch(
        "loadAvailableDeviceCommands",
        this.deviceGroup.details,
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getDeviceGroupById",
      "getIntegrationById",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getSupportedDeviceTypes",
      "getAvailableDeviceCommands",
    ]),
    title() {
      if (this.newDeviceGroup == undefined) return this.$t("_DeviceGroup");
      return this.$t("_DeviceGroup") + ": " + this.newDeviceGroup.name;
    },
    disabledExpertMode() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    deviceGroup() {
      return this.getDeviceGroupById(this.$route.query.id);
    },
    integration() {
      if (this.deviceGroup == undefined) return;
      return this.getIntegrationById(this.deviceGroup.integrationId);
    },
    vendors() {
      return [...new Set(this.getSupportedDeviceTypes.map((t) => t.vendor))];
    },
    deviceTypes() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter((t) => t.vendor === that.deviceGroup.details.vendor)
            .map((t) => t.deviceType),
        ),
      ];
    },
    firmwares() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter(
              (t) =>
                t.vendor == that.deviceGroup.details.vendor &&
                t.deviceType == that.deviceGroup.details.deviceType,
            )
            .map((t) => t.firmware),
        ),
      ];
    },
    definedCommands() {
      return this.getAvailableDeviceCommands;
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newDeviceGroup == undefined) return true;
      if (!this.isValid) return true;
      var keys = this.summaryItems
        .map((si) => si.key)
        .filter((k) => k !== "integration.name");
      return !isDifferent(this.newDeviceGroup, this.deviceGroup, keys);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newDeviceGroup == undefined) return true;
      var keys = this.summaryItems
        .map((si) => si.key)
        .filter((k) => k !== "integration.name");
      return !isDifferent(this.newDeviceGroup, this.deviceGroup, keys);
    },
    summaryItems() {
      if (this.newDeviceGroup == undefined) return [];
      if (this.integration == undefined) return [];

      var summaryItems = [
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            rules: nameRules(),
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "details.vendor",
          name: this.$t("_Vendor"),
          type: "select",
          props: { items: this.vendors, readonly: true },
        },
        {
          key: "details.deviceType",
          name: this.$t("_DeviceType"),
          type: "select",
          props: { items: this.deviceTypes, readonly: true },
        },
        {
          key: "details.firmware",
          name: this.$t("_Firmware"),
          type: "select",
          props: {
            items: this.firmwares,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "integration.name",
          name: this.$t("_Integration"),
          type: "text-field",
          props: {
            disabled: true,
          },
        },
        {
          key: "mapping",
          name: this.$t("_SubscribedGroup"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
      ];
      switch (this.integration.type) {
        case "CHIRPSTACK":
        case "DIGIMONDO":
          summaryItems.push({
            key: "groupDetails.infoList.nwAdr",
            name: this.$t("_MulticastAddress"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "groupDetails.infoList.nwSKey",
            name: this.$t("_MulticastNetworkSessionKey"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "groupDetails.infoList.appSKey",
            name: this.$t("_MulticastApplicationSessionKey"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;

        case "SWISSCOM":
          //  MPDT210068-3211
          summaryItems.push({
            key: "groupDetails.infoList.mcDevEUI",
            name: this.$t("_MulticastDevEUI"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "groupDetails.infoList.nwAdr",
            name: this.$t("_MulticastAddress"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "groupDetails.infoList.nwSKey",
            name: this.$t("_MulticastNetworkSessionKey"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          summaryItems.push({
            key: "groupDetails.infoList.appSKey",
            name: this.$t("_MulticastApplicationSessionKey"),
            type: "text-field",
            props: {
              readonly: !(
                this.isCurrentOrganizationsAdmin || this.isSystemAdmin
              ),
            },
          });
          break;
        default:
          break;
      }
      return summaryItems;
    },
  },
  methods: {
    undoChanges() {
      this.newDeviceGroup = cloneDeep(this.deviceGroup);
      this.newDeviceGroup.integration = cloneDeep(this.integration);
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        await delay(500);
        delete this.newDeviceGroup.integration;
        await this.$store.dispatch("updateDeviceGroup", this.newDeviceGroup);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async sendDefinedCommand(command) {
      await DeviceGroupsApi.sendCommand(this.deviceGroup, command);
    },
    async sendCustomCommand(command) {
      await DeviceGroupsApi.sendMessage(this.deviceGroup, command);
    },
  },
};
</script>

<style></style>
