<template>
  <BaseContentLoader :title="$t('_GlobalUsers')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        itemName="username"
        @canEdit="(user) => updateUser(user)"
      >
        <template v-slot:[`item.roles`]="{ item }">
          <v-chip-group
            multiple
            :value="item.roles"
            :disabled="item.props"
            column
          >
            <v-chip
              v-for="role in item.roles"
              :key="role"
              :value="role"
              outlined
            >
              {{ role }}</v-chip
            >
          </v-chip-group>
        </template>

        <!-- Edit Dialog-->
        <template v-slot:[`edit-dialog-content`]="{ item }">
          <v-row align="center">
            <v-col cols="4"
              ><span>{{ $t("_Roles") + ": " }}</span></v-col
            >
            <v-col cols="8">
              <v-chip-group multiple v-model="item.roles" column>
                <v-chip
                  v-for="role in getAllRoles"
                  :key="role"
                  :value="role"
                  outlined
                  filter
                  :disabled="role === 'USER'"
                >
                  {{ role }}</v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>
        </template>
      </BaseTable>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import {
  delay,
  sortByKey,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadGroupRoles", "priamos");
      await this.$store.dispatch("loadGroupGrants", "priamos");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentUser",
      "isSystemAdmin",
      "getAllRoles",
      "getAllGrants",
    ]),
    allUsers() {
      if (this.getAllGrants === undefined) return [];
      return [...new Set(this.getAllGrants.map((g) => g.id))];
    },
    tableItems() {
      if (this.getAllGrants == undefined) return [];
      var that = this;
      return this.allUsers
        .map((userId) => {
          var obj = Object.assign({}, { id: userId });
          obj.roles = that.getAllGrants
            .filter((g) => g.id === userId)
            .map((g) => g.role)
            .sort();
          obj.username = that.getAllGrants.find(
            (g) => g.id === userId,
          ).username;
          // Because it is not required at the moment
          //obj.removable = this.isSystemAdmin;
          obj.editable = true;
          obj.props = {
            disabled:
              that.getCurrentUser === obj.username || !this.isSystemAdmin,
          };
          return obj;
        })
        .sort((a, b) => sortByKey(b, a, "username"));
    },
    tableHeaders() {
      return [
        { text: this.$t("_Username").toUpperCase(), value: "username" },
        {
          text: this.$t("_Roles").toUpperCase(),
          value: "roles",
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async updateUser(user) {
      this.isLoading = true;
      try {
        const oldRoles = Object.assign(
          {},
          this.tableItems.find((u) => u.id === user.id),
        ).roles;
        var newRoles = user.roles;

        await delay(500);

        // Add Roles
        let addRoles = newRoles.filter((x) => !oldRoles.includes(x));
        for (let index = 0; index < addRoles.length; index++) {
          await this.$store.dispatch("addUserToGroup", {
            user: user,
            group: "priamos",
            role: addRoles[index],
          });
        }

        // Remove Roles
        let removeRoles = oldRoles.filter((x) => !newRoles.includes(x));
        for (let index = 0; index < removeRoles.length; index++) {
          await this.$store.dispatch("removeUserFromGroup", {
            user: user,
            group: "priamos",
            role: removeRoles[index],
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    // Registration is not required, because the user can register himself
    // async register() {},
  },
};
</script>
