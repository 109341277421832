<template>
  <BaseContentLoader :title="$t('_Responsibles')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        path="/alarming/responsibles/responsible?id="
        @canRemove="(item) => removeItem(item)"
      />

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/alarming/responsibles/add')"
          :disabled="disabledAddResponsible"
          data-cy="add-responsible-btn"
        >
          <v-icon left> mdi-account-plus </v-icon>
          {{ $t("_AddResponsible") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadResponsibles");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getAllResponsibles",
      "getCurrentOrganization",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    tableItems() {
      return this.getAllResponsibles
        .filter((r) => r.organizationId === this.getCurrentOrganization.id)
        .map((r) => {
          let obj = Object.assign({}, r);
          obj.removable = true;
          obj.props = {
            disabled: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          };
          return obj;
        });
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          columnFilter: true,
          value: "name",
        },
        {
          text: this.$t("_Email").toUpperCase(),
          columnFilter: true,
          value: "email",
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    disabledAddResponsible() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("deleteResponsible", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
