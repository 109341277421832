<template>
  <BaseContentLoader :title="$t('_PersonalSettings')">
    <template slot="content">
      <v-radio-group v-model="language" row :label="$t('_Language') + ': '">
        <v-radio
          v-for="entry in languages"
          :label="entry.title"
          :key="entry.language"
          :value="entry.language"
        >
        </v-radio
      ></v-radio-group>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      languages: [
        { flag: "gb", language: "en", title: "English" },
        { flag: "de", language: "de", title: "Deutsch" },
        // MPDT210068-3215
        { flag: "it", language: "it", title: "Italiano" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getLocale"]),
    language: {
      get: function () {
        return this.getLocale;
      },
      set: function (locale) {
        this.$store.dispatch("setLocale", locale);
      },
    },
  },
};
</script>
