<!-- MPDT210068-3719 -->
<template>
  <BaseContentLoader :title="$t('_CustomAlarms')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        path="/alarming/custom-alarms/external-alarm?id="
        @canRemove="(item) => removeItem(item)"
      />
      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          :disabled="disabledAddCustomAlarm"
          data-cy="add-external-alarm-btn"
          @click="$router.push('/alarming/custom-alarms/add')"
        >
          {{ $t("_AddCustomAlarm") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadDevices");
      await this.$store.dispatch("loadCustomAlarms");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getDeviceById",
      "getAllCustomAlarms",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    tableItems() {
      return this.getAllCustomAlarms
        .filter((a) => a.organizationId === this.getCurrentOrganization.id)
        .map((a) => {
          var obj = Object.assign({}, a);
          obj.removable =
            this.isSystemAdmin || this.isCurrentOrganizationsAdmin;

          // Added this line to show view although device is not available anymore
          obj.deviceName =
            this.getDeviceById(obj.monitorId) != undefined
              ? this.getDeviceById(obj.monitorId).name
              : "";

          return obj;
        });
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Device").toUpperCase(),
          value: "deviceName",
        },
        { text: this.$t("_Title").toUpperCase(), value: "title" },
        { text: this.$t("_Description").toUpperCase(), value: "description" },
        {
          text: this.$t("_AlarmType").toUpperCase(),
          value: "alarmType",
          columnFilter: true,
        },

        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledAddCustomAlarm() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("deleteCustomAlarm", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
