<template>
  <v-col class="pl-1 pr-8" cols="3">
    <v-card>
      <v-img
        :src="imagePath"
        lazy-src="@/assets/grovez.png"
        aspect-ratio="1.7"
        cover
      >
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular
              color="grey-lighten-4"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-slot:error>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular
              color="grey-lighten-4"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-img>
      <v-row align="center" justify="center" style="height: 6rem">
        <v-col class="text-subtitle-2 text-center font-weight-bold my-4 mx-2">
          {{ value.name }}
        </v-col>
      </v-row>
      <div class="text-h4 text-center primary--text font-weight-black">
        {{ price }}
      </div>
      <div class="text-center">{{ value.description.split(" | ")[3] }} *</div>
      <v-divider class="mx-16 my-6"></v-divider>
      <div v-if="value.bookedQuantity > 0" class="text-center primary--text">
        {{ value.bookedQuantity }}
        {{ $t("_BookedLicences") }}
      </div>
      <div v-else class="text-center">
        {{ $t("_NoLicenceBooked") }}
      </div>
      <v-spacer class="my-4"></v-spacer>
      <v-list>
        <v-list-item v-for="feature in this.features" v-bind:key="feature">
          <v-list-item-icon style="height: 3rem">
            <v-icon color="black">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ feature }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions class="px-8 pb-6 my-8">
        <v-spacer></v-spacer
        ><BaseDialogButton
          color="primary"
          :buttonText="$t('_BookPackage')"
          :confirmText="$t('_OrderLicence')"
          :dialogTitle="$t('_BookPackage')"
          width="600"
          @confirm="createSubscription()"
        >
          <template slot="content">
            <div class="px-3 py-3 mb-3" style="background: #ebecec">
              <h3>
                {{ value.name }}
                <p class="primary--text">({{ getCurrentOrganization.name }})</p>
              </h3>
              <span> {{ value.description }}</span>
              <v-row class="px-3 mt-4"
                ><v-text-field
                  :label="$t('_PricePerUnit')"
                  color="black"
                  :value="singleCosts"
                  readonly
                ></v-text-field
                ><v-text-field
                  :label="$t('_Quantity')"
                  v-model="quantity"
                  color="black"
                  type="number"
                  :rules="quantityRules"
                ></v-text-field
                ><v-text-field
                  :value="netCosts"
                  :label="$t('_Net')"
                  color="black"
                  readonly
                ></v-text-field
              ></v-row>
              <v-row class="mt-n4"
                ><v-spacer></v-spacer>
                <v-col cols="4"
                  ><p class="text-right font-weight-bold">
                    {{ totalCosts }}
                  </p></v-col
                >
              </v-row>
            </div>
            <v-spacer style="height: 1rem"></v-spacer>
            <v-textarea
              filled
              v-model="internalOrderId"
              auto-grow
              rows="1"
              :label="$t('_InternalOrderId')"
              :hint="$t('_ReferenceHint')"
              persistent-hint
              color="black"
            ></v-textarea>
            <v-spacer style="height: 2rem"></v-spacer>
            <v-row>
              <v-col cols="6">
                <strong>{{ $t("_BillingAddress") }}</strong>
                <br />
                <br />
                {{ getBillingAccount.companyName }} <br />
                {{
                  [
                    getBillingAccount.firstName,
                    getBillingAccount.lastName,
                  ].join(" ")
                }}<br />{{
                  [
                    getBillingAccount.address.street,
                    getBillingAccount.address.houseNumber,
                  ].join(" ")
                }}<br />{{
                  [
                    getBillingAccount.address.postalCode,
                    getBillingAccount.address.city,
                  ].join(" ")
                }}
              </v-col>
              <v-col cols="6">
                <strong>{{ $t("_ContractPartner") }}</strong>
                <br />
                <br />
                {{ contractPartner.name }} <br />
                {{
                  [contractPartner.street, contractPartner.houseNumber].join(
                    " ",
                  )
                }}<br />{{
                  [contractPartner.zipCode, contractPartner.city].join(" ")
                }}<br />{{ [contractPartner.country].join(" ") }}
              </v-col>
            </v-row>
            <strong></strong>
            <br />
            <br />
            <v-row align="center">
              <v-col cols="1">
                <v-checkbox
                  v-model="hasAgreed"
                  dense
                  :rules="agreementRules"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-row align="baseline">
                  <div
                    v-for="(text, index) in licenceTermsWithLinks"
                    v-bind:key="index"
                  >
                    <b v-if="index != 1 && index != 3">{{ text }}</b>
                    <a
                      href="https://proficloud.io/terms-and-conditions"
                      target="_blank"
                      v-if="index == 1"
                      >{{ text }}</a
                    >
                    <a
                      href="https://proficloud.io/software-license-terms-international"
                      target="_blank"
                      v-if="index == 3"
                      >{{ text }}</a
                    >
                    &nbsp;
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </BaseDialogButton>
        <v-spacer
      /></v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { countries } from "../data/ContractPartners";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      default: function () {
        switch (this.value.id) {
          case "1386966":
            return [
              this.$t("_10YearsContractPeriod"),
              this.$t("_IsolatedSmartLightingService"),
              this.$t("_RenewedAutomatically"),
            ];
          case "1386968":
            return [
              this.$t("_5YearsContractPeriod"),
              this.$t("_IsolatedSmartLightingService"),
              this.$t("_RenewedAutomatically"),
            ];
          case "1386962":
            return [
              this.$t("_1YearContractPeriod"),
              this.$t("_ControllableLuminaireSegment"),
              this.$t("_RenewedAutomatically"),
            ];
          case "1386964":
            return [
              this.$t("_1YearContractPeriod"),
              this.$t("_ControllableLuminaireUnit"),
              this.$t("_RenewedAutomatically"),
            ];
          default:
            return [];
        }
      },
    },
  },
  data() {
    return {
      isValid: false,
      internalOrderId: "",
      quantity: 0,
      hasAgreed: false,
    };
  },
  computed: {
    price() {
      let char = "";
      switch (this.value.pricing.currency) {
        case "EUR":
          char = "€";
          break;
        default:
          char = this.value.pricing.currency;
          break;
      }
      return char + " " + this.value.pricing.recurringFee;
    },
    currencySign() {
      let sign = "";
      switch (this.value.pricing.currency) {
        case "EUR":
          sign = "€";
          break;
        default:
          break;
      }
      return sign;
    },
    singleCosts() {
      return this.value.pricing.recurringFee.toFixed(2) + this.currencySign;
    },
    netCosts() {
      return (
        (this.value.pricing.recurringFee * this.quantity).toFixed(2) +
        this.currencySign
      );
    },
    totalCosts() {
      return (
        this.$t("_TotalCosts") +
        ": " +
        (this.value.pricing.recurringFee * this.quantity).toFixed(2) +
        this.currencySign
      );
    },
    quantityRules() {
      return [(v) => !!v || "", (v) => v > 0 || ""];
    },
    agreementRules() {
      return [(v) => v || ""];
    },
    licenceTermsWithLinks() {
      return this.$t("__CFA_ReadTerm").split("$<a>");
    },
    contractPartner() {
      let shortName = this.getBillingAccount.address.country;
      let contractPartner = countries[shortName];

      return {
        name: contractPartner.subsidiary,
        street: contractPartner.street,
        houseNumber: contractPartner.houseNumber,
        city: contractPartner.city,
        zipCode: contractPartner.zipCode,
        country: contractPartner.name,
      };
    },
    ...mapGetters(["getBillingAccount", "getCurrentOrganization"]),
  },
  methods: {
    createSubscription() {
      var bookingPackage = {
        packageId: this.value.id,
        quantity: this.quantity,
        sendNoConfirmationMail: false,
        internalOrderId: this.internalOrderId,
      };
      this.$store.dispatch("createSubscription", bookingPackage);
    },
  },
};
</script>
