<!-- MPDT210068-3211 - In this component the user has to enter all required
input parameters to establish a connection between our platform and digimondo
-->
<template>
  <div>
    <v-text-field
      v-model="value.url"
      :label="$t('_Url') + ' *'"
      :rules="urlRules"
    ></v-text-field>
    <v-text-field
      v-model="value.applicationId"
      label="Application-Id *"
      :rules="applicationIdRules"
      type="number"
    ></v-text-field>
    <v-text-field
      v-model="value.organizationId"
      label="Organization-Id *"
      :rules="organizationIdRules"
      type="number"
    ></v-text-field>
    <v-textarea
      class="py-2"
      v-model="value.apiToken"
      outlined
      label="API-Key *"
      :rules="apiKeyRules"
    ></v-textarea>
  </div>
</template>

<script>
import {
  urlRules,
  applicationIdRules,
  apiKeyRules,
  organizationIdRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          apiToken: "",
          url: "",
          applicationId: null,
          organizationId: "",
        };
      },
    },
  },
  computed: {
    urlRules() {
      return urlRules();
    },
    applicationIdRules() {
      return applicationIdRules();
    },
    apiKeyRules() {
      return apiKeyRules();
    },
    organizationIdRules() {
      return organizationIdRules();
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
