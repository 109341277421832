<template>
  <v-row justify="center">
    <BaseTile
      v-if="hasSLSAccess"
      :title="$t('_SmartLighting')"
      @click="navigateToSLS()"
    />
    <BaseTile
      v-if="hasSVSAccess"
      :title="$t('_SmartValve')"
      @click="navigateToSVS()"
    />
    <BaseTile
      v-if="hasSTSAccess"
      title="Traffic Monitoring"
      @click="navigateToSTS()"
    />
    <BaseTile
      v-if="hasSESAccess"
      title="Environmental Monitoring"
      @click="navigateToSES()"
    />
    <BaseTile
      v-if="hasLpwanAccess"
      title="LPWAN Service"
      @click="navigateToLPWAN()"
    />
    <p
      class="py-8 my-8"
      v-if="
        !(
          hasSLSAccess ||
          hasSVSAccess ||
          hasSTSAccess ||
          hasSESAccess ||
          hasLpwanAccess
        )
      "
    >
      {{ $t("__CFANoServices") }}
    </p>
  </v-row>
</template>

<script>
export default {
  computed: {
    hasSLSAccess() {
      return this.keycloak.tokenParsed.realm_access.roles.includes(
        "sls-frontend-access",
      );
    },
    hasSVSAccess() {
      return this.keycloak.tokenParsed.realm_access.roles.includes(
        "svs-frontend-access",
      );
    },
    hasSTSAccess() {
      return this.keycloak.tokenParsed.realm_access.roles.includes(
        "sts-frontend-access",
      );
    },
    hasSESAccess() {
      return this.keycloak.tokenParsed.realm_access.roles.includes(
        "ses-frontend-access",
      );
    },
    hasLpwanAccess() {
      return this.keycloak.tokenParsed.realm_access.roles.includes(
        "lpwan-access",
      );
    },
  },
  methods: {
    navigateToSLS() {
      if (process.env.NODE_ENV === "development") {
        window.location = "http://localhost:8082/sls/";
      } else {
        window.location.pathname = "/sls/";
      }
    },
    navigateToSVS() {
      if (process.env.NODE_ENV === "development") {
        window.location = "http://localhost:8083/svs/";
      } else {
        window.location.pathname = "/svs/";
      }
    },
    navigateToSTS() {
      if (process.env.NODE_ENV === "development") {
        window.location = "http://localhost:8085/sts/";
      } else {
        window.location.pathname = "/sts/";
      }
    },
    navigateToSES() {
      if (process.env.NODE_ENV === "development") {
        window.location = "http://localhost:8084/ses/";
      } else {
        window.location.pathname = "/ses/";
      }
    },
    navigateToLPWAN() {
      window.open(process.env.VUE_APP_LPWAN_URL, "_blank").focus();
    },
  },
};
</script>
