<template>
  <BaseContentLoader :title="$t('_Services')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        path="/organization/services/service?id="
        @canRemove="(item) => removeItem(item)"
      >
        <template v-slot:[`item.isEnabled`]="{ item }">
          <v-icon v-if="item.isEnabled" color="primary">mdi-check</v-icon>
          <v-icon v-else color="error">mdi-cancel</v-icon>
        </template>
      </BaseTable>

      <v-row class="pt-8">
        <!-- There are Breadcrumbs so no back buttons are needed-->
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/organization/services/activation')"
          data-cy="activate-service-btn"
          :disabled="disabledEditServiceActivation"
          ><v-icon left>mdi-key</v-icon>
          {{ $t("_EditServiceActivation") }}</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          data-cy="btn-add-service"
          @click="$router.push('/organization/services/add')"
          :disabled="disabledAddService"
        >
          <v-icon left>mdi-plus-thick</v-icon>
          {{ $t("_AddService") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadServices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getServices",
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
    ]),
    tableItems() {
      return this.getServices
        .filter((s) => s.organizationId == this.getCurrentOrganization.id)
        .map((a) => {
          var obj = Object.assign({}, a);
          obj.removable = true;
          obj.props = {
            disabled: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          };
          return obj;
        });
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
        },
        {
          text: this.$t("_Type").toUpperCase(),
          value: "type",
          columnFilter: true,
        },
        {
          text: this.$t("_Activated").toUpperCase(),
          value: "isEnabled",
          columnFilter: true,
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledEditServiceActivation() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledAddService() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("removeService", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
