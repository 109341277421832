<template>
  <div data-cy="billing-data">
    <v-text-field
      v-model="value.emailAddress"
      :label="$t('_Email') + ' *'"
      :rules="emailRules"
      data-cy="tf-email"
    ></v-text-field>
    <v-text-field
      v-model="value.firstName"
      :label="$t('_FirstName') + ' *'"
      :rules="nameRules"
      data-cy="tf-first-name"
    ></v-text-field>
    <v-text-field
      v-model="value.lastName"
      :label="$t('_LastName') + ' *'"
      :rules="nameRules"
      data-cy="tf-last-name"
    ></v-text-field>
    <v-text-field
      v-model="value.companyName"
      :label="$t('_CompanyName') + ' *'"
      :rules="nameRules"
      data-cy="tf-company-name"
    ></v-text-field>
    <v-text-field
      v-model="value.address.street"
      :label="$t('_Street') + ' *'"
      :rules="nameRules"
      data-cy="tf-street"
    ></v-text-field>
    <v-text-field
      v-model="value.address.houseNumber"
      :label="$t('_HouseNumber') + ' *'"
      :rules="nameRules"
      data-cy="tf-house-number"
    ></v-text-field>
    <v-text-field
      v-model="value.address.postalCode"
      :label="$t('_PostalCode') + ' *'"
      :rules="nameRules"
      data-cy="tf-postal-code"
    ></v-text-field>
    <v-text-field
      v-model="value.address.city"
      :label="$t('_CityName') + ' *'"
      :rules="nameRules"
      data-cy="tf-city"
    ></v-text-field>
    <v-text-field
      v-model="value.address.country"
      :label="$t('_Country') + ' *'"
      :rules="nameRules"
      data-cy="tf-country"
    ></v-text-field>
    <v-text-field
      v-model="value.vatId"
      :label="$t('_VATNumber')"
      :rules="nameRules"
      data-cy="tf-vat-number"
    ></v-text-field>
    <v-text-field
      v-model="value.pxcCustomerId"
      :label="$t('_PxCCustomerId')"
      data-cy="tf-pxc-customer-id"
    ></v-text-field>
  </div>
</template>

<script>
import {
  nameRules,
  emailRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: function () {
        return {
          address: { street: "", houseNumber: "", city: "", postalCode: "" },
          companyName: "",
          emailAddress: "",
          firstName: "",
          language: "",
          lastName: "",
          pxcCustomerId: "",
          vatId: "",
        };
      },
    },
  },
  computed: {
    emailRules() {
      return emailRules();
    },
    nameRules() {
      return nameRules();
    },
  },
  watch: {
    value: function () {
      this.$emit("input", this.value);
    },
  },
};
</script>
