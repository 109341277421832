<template>
  <BaseContentLoader :title="$t('_AddDataSharing')" :isLoading="false">
    <template v-slot:content>
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="save"
        @cancel="$router.push('/dad/datasharing')"
      >
        <template slot="tab-item-0">
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :label="$t('_Name')"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedProtocol"
                :items="protocols"
                :label="$t('_Protocol')"
                :rules="[(v) => !!v || 'Protocol must be provided']"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedDeviceType"
                :items="deviceTypes"
                :label="$t('_DeviceType')"
                :rules="[(v) => !!v || 'Device type must be provided']"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="targetAddress"
                :label="$t('_TargetAddress')"
                hint="e.g. https://example.com/sharing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="mb-3">
              <!-- Dynamic key and values for http headers -->
              <div v-if="selectedProtocol === 'HTTP'">
                <v-card-subtitle>HTTP Header</v-card-subtitle>
                <BaseDynamicFields v-model="httpHeaders"></BaseDynamicFields>
              </div>
              <!-- Mqtt Sharing-->
              <div v-if="selectedProtocol === 'MQTT'">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="mqttConfig['userName']"
                      :label="$t('_Username')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="mqttConfig['password']"
                      :label="$t('_Password')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="mqttConfig['topic']"
                      label="MQTT Topic"
                      placeholder="/topic/devices/..."
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </template>

        <template slot="tab-item-1">
          <v-row class="pa-2">
            <v-col>
              <pre class="caption" style="white-space: pre-line">
                  Please define your code in the following function.
                  Tipps:
                      1. The variable "message" is just an illustration of the message structure and has no effect in the real execution.
                      2. In the example message, content under the field "data" is device dependent. Please get informed what are they before writing code for that part.
                      3. Feel free to define any other helper functions. But the main function is "process", which returns the final result.
              </pre>
            </v-col>
          </v-row>

          <BaseCodeEditor
            v-model="code"
            :protocolDetails="protocolDetails"
            @executionSuccess="executionSuccess = true"
            :device-details="selectedDeviceType"
          />
        </template>
      </BaseTabDialog>

      <v-dialog v-model="showConfirmDialog" :persistent="true" width="auto">
        <BaseDialog title="Caution" :actions="confirmDialogActions">
          <template v-slot:content>
            The script hasn't passed the trial execution yet, do you really want
            to save it?
          </template>
          <template v-slot:left-actions>
            <v-btn @click="showConfirmDialog = false">{{
              $t("_Cancel")
            }}</v-btn>
            <v-spacer />
          </template>
        </BaseDialog>
      </v-dialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import DataSharingApi from "@/data/api/SharingApi";
import BaseCodeEditor from "@/views/services/dad/sharing/SharingCodeEditor.vue";
import BaseDynamicFields from "@/components/BaseDynamicFields.vue";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
import { mapGetters } from "vuex";
//import BaseDynamicForm from "@/components/BaseDynamicForm.vue";

export default {
  components: {
    BaseCodeEditor,
    BaseDynamicFields,
  },
  data() {
    return {
      count: 1,
      keys: {},
      values: {},
      httpHeaders: {},
      mqttConfig: {},
      name: "",
      selectedProtocol: "",
      protocols: ["HTTP", "MQTT"],
      selectedDeviceType: "",
      targetAddress: "",

      code:
        "function process(message){\n" +
        "    var processResult = message;\n" +
        "\n" +
        "    return processResult;\n" +
        "}",
      executionSuccess: false,

      showConfirmDialog: false,
      confirmDialogActions: [
        {
          buttonText: "Confirm",
          color: "error white--text",
          disabled: false,
          method: () => {
            this.showConfirmDialog = false;
            this.saveSharing();
          },
        },
      ],
    };
  },
  computed: {
    deviceTypes() {
      return this.getSupportedDeviceTypes().map((v) => {
        return {
          value: v,
          text: v.vendor + "/" + v.deviceType + "/" + v.firmware,
        };
      });
    },
    protocolDetails() {
      if (this.selectedProtocol === "HTTP")
        return {
          protocol: "HTTP",
          headers: this.httpHeaders,
          url: this.targetAddress,
        };
      else if (this.selectedProtocol === "MQTT")
        return {
          protocol: "MQTT",
          username: this.mqttConfig.username,
          password: this.mqttConfig.password,
          url: this.targetAddress,
        };
      return "";
    },
    subHeaders() {
      return [this.$t("_AddDataSharingStep1"), this.$t("_AddDataSharingStep2")];
    },
    tabHeaders() {
      return [this.$t("_AddDataSharingTab1"), this.$t("_AddDataSharingTab2")];
    },
    nameRules: () => nameRules(),
  },
  methods: {
    ...mapGetters(["getSupportedDeviceTypes"]),

    saveSharing() {
      var router = this.$router;
      var payload = {
        name: this.name,
        script: this.code,
        protocol: this.selectedProtocol,
        headers: this.httpHeaders,
        mqttConfig: this.mqttConfig,
        address: this.targetAddress,
        deviceDetails: this.selectedDeviceType,
      };

      DataSharingApi.createSharing(payload).then(() =>
        router.push("/dad/datasharing"),
      );
    },
    save() {
      if (!this.executionSuccess) {
        this.showConfirmDialog = true;
      } else this.saveSharing();
    },
  },
  watch: {
    code() {
      this.executionSuccess = false;
    },
  },
};
</script>
