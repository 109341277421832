<!-- MPDT210068-3719 -->
<template>
  <BaseContentLoader :title="$t('_CustomAlarm')" :isLoading="isLoading">
    <template slot="content">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newCustomAlarm"
          :items="summaryItems"
          isEditable
        ></BaseSummary>

        <v-row
          ><v-col cols="12">
            <v-card-title>{{ $t("_Condition") }}</v-card-title>
            <CodeEditor
              class="fix-editor-layout mb-6"
              theme="light"
              font_size="15px"
              :copy_code="false"
              min_width="100%"
              v-model="newCustomAlarm.testData"
              @change="executionSuccess = false"
            />
            <CodeEditor
              class="fix-editor-layout mb-6"
              theme="light"
              font_size="15px"
              min_width="100%"
              v-model="newCustomAlarm.code"
              @change="executionSuccess = false"
            >
            </CodeEditor> </v-col
        ></v-row>
        <v-input
          :value="executionSuccess"
          :rules="codeRules"
          style="visibility: hidden"
        >
        </v-input>
      </v-form>

      <v-row justify="space-between">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3 mb-6"
          :disabled="executionSuccess"
          color="primary"
          @click="tryExecute()"
        >
          {{ this.$t("_TryExecution") }}
        </v-btn>
      </v-row>

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}</v-btn
        >
        <v-btn
          class="mx-3"
          @click="saveChanges()"
          :disabled="disabledSave"
          color="primary"
          >{{ $t("_SaveChanges") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import {
  isDifferent,
  alarmTypes,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { cloneDeep } from "lodash";
import CodeEditor from "simple-code-editor";
import CustomAlarmsApi from "@/data/api/CustomAlarmsApi";

export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      isLoading: true,
      isValid: false,
      newCustomAlarm: undefined,
      executionSuccess: true,
    };
  },

  async mounted() {
    try {
      this.newCustomAlarm = cloneDeep(
        await this.$store.dispatch("loadCustomAlarm", this.$route.query.id),
      );
      this.newCustomAlarm.device = cloneDeep(
        await this.$store.dispatch("loadDevice", this.newCustomAlarm.monitorId),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCustomAlarmById",
      "getDeviceById",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    customAlarm() {
      return this.getCustomAlarmById(this.$route.query.id);
    },
    device() {
      if (this.customAlarm == undefined) return;
      return this.getDeviceById(this.customAlarm.monitorId);
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newCustomAlarm == undefined) return true;
      if (this.customAlarm == undefined) return true;
      if (!this.isValid) return true;

      var keys = this.summaryItems
        .map((si) => si.key)
        .filter((k) => k !== "device.name");
      keys.push("code");
      keys.push("testData");
      return !isDifferent(this.newCustomAlarm, this.customAlarm, keys);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newCustomAlarm == undefined) return true;
      if (this.customAlarm == undefined) return true;

      var keys = this.summaryItems
        .map((si) => si.key)
        .filter((k) => k !== "device.name");
      keys.push("code");
      keys.push("testData");
      return !isDifferent(this.newCustomAlarm, this.customAlarm, keys);
    },
    codeRules() {
      return [(v) => v || "Error"];
    },
    summaryItems() {
      return [
        {
          key: "id",
          name: "ID",
          type: "text-field",
          props: {
            disabled: true,
          },
        },
        {
          key: "title",
          name: this.$t("_Title"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "alarmType",
          name: this.$t("_AlarmType"),
          type: "select",
          props: {
            items: this.alarmTypes,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "description",
          name: this.$t("_Description"),
          type: "textarea",
          props: {
            outlined: true,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "device.name",
          name: this.$t("_Device"),
          type: "text-field",
          props: { disabled: true },
        },
      ];
    },
    alarmTypes() {
      let types = alarmTypes;
      types.push("FAILURE");
      return types;
    },
  },
  methods: {
    async tryExecute() {
      try {
        const payload = {
          code: this.newCustomAlarm.testData + this.newCustomAlarm.code,
        };
        await CustomAlarmsApi.tryExecuteCode(payload);
        this.executionSuccess = true;
      } catch (err) {
        console.log(err);
      }
    },
    undoChanges() {
      this.newCustomAlarm = cloneDeep(this.customAlarm);
      this.newCustomAlarm.device = cloneDeep(this.device);
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        let payload = cloneDeep(this.newCustomAlarm);
        delete payload.device;
        await this.$store.dispatch("updateCustomAlarm", payload);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    newCustomAlarm: {
      handler: function () {
        this.executionSuccess = false;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/*This style fixes the codeeditor layout problem, which is introduced by v-card-text*/
.fix-editor-layout {
  line-height: normal;
}
</style>
