<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="exportTSD()"
        @cancel="$router.push('/dad/devices/device?id=' + $route.query.id)"
      >
        <template slot="tab-item-0">
          <v-col cols="8">
            <v-row>
              <BaseDatePicker
                v-model="startDate"
                :label="$t('_StartDate')"
                :rules="dateRules"
                :min="minStartDate"
                :max="maxStartDate"
              ></BaseDatePicker>
              <v-spacer></v-spacer>
              <BaseDatePicker
                v-model="endDate"
                :label="$t('_EndDate')"
                :rules="dateRules"
                :min="minEndDate"
                :max="maxEndDate"
              ></BaseDatePicker>
            </v-row>
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { exportCSVData } from "@smart-city-plattform/vue-component-library/src/helper/helper";

import DevicesApi from "@/data/api/DevicesApi";
import moment from "moment/moment";
export default {
  data() {
    return {
      isLoading: true,
      startDate: undefined,
      endDate: undefined,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadDevice", this.$route.query.id);
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getDeviceById", "getLocale"]),
    device() {
      return this.getDeviceById(this.$route.query.id);
    },
    subHeaders() {
      return [`${this.$t("__CFA1_ExportDeviceData")}`];
    },
    tabHeaders() {
      return [this.$t("_ExportData")];
    },
    dateRules() {
      return [(v) => !!v || "Date is required"];
    },
    minStartDate() {
      return undefined;
    },
    maxStartDate() {
      if (this.endDate === undefined) return undefined;

      return moment(this.endDate).startOf("day").toISOString();
    },
    minEndDate() {
      if (this.startDate === undefined) return undefined;

      return moment(this.startDate).add(2, "days").startOf("day").toISOString();
    },
    maxEndDate() {
      return undefined;
    },
  },
  methods: {
    async exportTSD() {
      try {
        let headersSet = new Set([]);

        const tsdData = (
          await DevicesApi.exportTSD(
            this.device.id,
            moment(this.startDate).startOf("day").valueOf(),
            moment(this.endDate).add(1, "days").valueOf(),
          )
        ).data.map((d) => {
          let obj = {
            time: moment(d.time.epochSecond * 1000)
              .locale(this.getLocale)
              .format("lll"),
            ...JSON.parse(d.value),
          };

          headersSet.add("time");
          Object.keys(JSON.parse(d.value)).forEach((key) => {
            headersSet.add(key);
          });

          return obj;
        });

        exportCSVData(
          [
            this.device.name,
            this.device.deviceId,
            moment(this.startDate).toISOString(),
            moment(this.endDate).toISOString(),
          ].join("-"),
          tsdData,
          true,
          [...headersSet].map((h) => {
            return { value: h, text: h };
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
