<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="addService()"
        @cancel="$router.push('/organization/services')"
      >
        <template slot="tab-item-0">
          <v-col cols="5">
            <v-text-field
              :label="$t('_ServiceName') + ' *'"
              v-model="name"
              :rules="nameRules"
              data-cy="tf-name"
            ></v-text-field>
          </v-col>
        </template>
        <template slot="tab-item-1">
          <v-col cols="4">
            <BaseSelect
              v-model="serviceType"
              :items="serviceTypes"
              :prepend-inner-icon="
                serviceType !== undefined ? serviceType.icon : ''
              "
            />
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
import {
  delay,
  serviceTypes,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      name: "",
      serviceType: undefined,
    };
  },
  mounted() {
    this.serviceType = this.serviceTypes[0];
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
    ]),
    subHeaders() {
      return [this.$t("__CFA1_AddService"), this.$t("__CFA2_AddService")];
    },
    tabHeaders() {
      return [this.$t("_Name"), this.$t("_ServiceType")];
    },
    nameRules() {
      return nameRules();
    },
    serviceTypes: () => {
      let types = serviceTypes;

      types.push({
        id: "SES",
        name: "Environmental Monitoring",
        icon: "mdi-leaf",
      });

      types.push({
        id: "STS",
        name: "Traffic Monitoring",
        icon: "mdi-highway",
      });

      if (process.env.NODE_ENV === "development") {
        return types;
      }

      return types.filter((s) =>
        process.env.VUE_APP_ACTIVE_SERVICES.includes(s.id),
      );
    },
  },
  methods: {
    async addService() {
      this.isLoading = true;
      try {
        let payload = {
          service: {
            name: this.name,
            type: this.serviceType.id,
            organizationId: this.getCurrentOrganization.id,
          },
          activationOption: "NO_ACTIVATION",
        };

        await delay(500);
        await this.$store.dispatch("createService", payload);
        await this.$router.push("/organization/services");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
