var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.hasSLSAccess)?_c('BaseTile',{attrs:{"title":_vm.$t('_SmartLighting')},on:{"click":function($event){return _vm.navigateToSLS()}}}):_vm._e(),(_vm.hasSVSAccess)?_c('BaseTile',{attrs:{"title":_vm.$t('_SmartValve')},on:{"click":function($event){return _vm.navigateToSVS()}}}):_vm._e(),(_vm.hasSTSAccess)?_c('BaseTile',{attrs:{"title":"Traffic Monitoring"},on:{"click":function($event){return _vm.navigateToSTS()}}}):_vm._e(),(_vm.hasSESAccess)?_c('BaseTile',{attrs:{"title":"Environmental Monitoring"},on:{"click":function($event){return _vm.navigateToSES()}}}):_vm._e(),(_vm.hasLpwanAccess)?_c('BaseTile',{attrs:{"title":"LPWAN Service"},on:{"click":function($event){return _vm.navigateToLPWAN()}}}):_vm._e(),(
      !(
        _vm.hasSLSAccess ||
        _vm.hasSVSAccess ||
        _vm.hasSTSAccess ||
        _vm.hasSESAccess ||
        _vm.hasLpwanAccess
      )
    )?_c('p',{staticClass:"py-8 my-8"},[_vm._v(" "+_vm._s(_vm.$t("__CFANoServices"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }