// MPDT210068-3719
import Base from "@/data/api/Base";

export default {
  createCustomAlarm(customAlarm) {
    return Base(true).http.post("/alarming/custom-alarms", customAlarm);
  },
  listCustomAlarms() {
    return Base(true).http.get(
      "/alarming/custom-alarms?size=" + Base(false).maxSize,
    );
  },
  getCustomAlarm(customAlarmId) {
    return Base(true).http.get("/alarming/custom-alarms/" + customAlarmId);
  },
  updateCustomAlarm(customAlarm) {
    return Base(true).http.patch(
      "/alarming/custom-alarms/" + customAlarm.id,
      customAlarm,
    );
  },
  deleteCustomAlarm(customAlarm) {
    return Base(true).http.delete("/alarming/custom-alarms/" + customAlarm.id);
  },
  tryExecuteCode(code) {
    return Base(true).http.post("/alarming/custom-alarms/dryrun", code);
  },
};
