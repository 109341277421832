import Base from "@/data/api/Base";

export default {
  addUserToGroup(group, username) {
    return Base(true).http.post(
      "/users/username/" + username + "/group/" + group,
    );
  },

  getAllUsersByGroup(group) {
    return Base(true).http.get(
      "/users/group/" + group + "?size=" + Base(false).maxSize,
    );
  },

  listRolesByGroup(group) {
    return Base(true).http.get("/users/group/roles/" + group);
  },

  removeUserFromGroup(group, username) {
    return Base(true).http.delete(
      "/users/username/" + username + "/group/" + group,
    );
  },

  deleteUser(user) {
    return Base(false).http.delete("/users/" + user.id);
  },
};
