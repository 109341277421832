<template>
  <BaseContentLoader :title="$t('_Devices')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        v-model="selectedDevices"
        :headers="tableHeaders"
        :items="tableItems"
        @canRemove="(item) => removeItem(item)"
        path="/dad/devices/device?id="
      >
        <!-- MPDT210068-2934:-->
        <template v-slot:[`item.isEnabled`]="{ item }">
          <v-icon v-if="item.isEnabled" color="primary">mdi-check</v-icon>
          <v-icon v-else color="error">mdi-cancel</v-icon>
        </template>
      </BaseTable>

      <v-row class="pt-8">
        <!-- MPDT210068-2934-->
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/dad/devices/activation')"
          :disabled="disabledEditDevicesActivation"
        >
          <v-icon left>mdi-key</v-icon>
          {{ $t("_EditDevicesActivation") }}</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/dad/devices/register')"
          :disabled="disabledRegister"
          >{{ $t("_RegisterDevices") }}</v-btn
        >
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/dad/devices/onboard')"
          :disabled="disabledOnboard"
          >{{ $t("_OnboardDevices") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      selectedDevices: [],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadServices");
      await this.$store.dispatch("loadIntegrations");
      await this.$store.dispatch("loadDevices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getAllDevices",
      "getServiceById",
      "getIntegrationById",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    tableItems() {
      var that = this;
      return this.getAllDevices
        .filter((a) => a.organizationId == this.getCurrentOrganization.id)
        .map((a) => {
          var obj = Object.assign({}, a);
          let service = that.getServiceById(a.mapping);
          obj.service = service != undefined ? service.name : "---";

          let integration = that.getIntegrationById(a.integrationId);
          obj.integration = integration != undefined ? integration.name : "---";
          obj.removable = true;
          obj.props = {
            disabled: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          };

          if (obj.details != undefined) {
            obj.type = [
              obj.details.vendor,
              obj.details.deviceType,
              obj.details.firmware,
            ].join(" ");
          }
          obj.tags = obj.tags.join(", ");

          return obj;
        });
    },
    tableHeaders() {
      return [
        { text: this.$t("_Name").toUpperCase(), value: "name" },
        {
          text: this.$t("_Type").toUpperCase(),
          value: "type",
          columnFilter: true,
        },
        {
          text: this.$t("_Integration").toUpperCase(),
          value: "integration",
          columnFilter: true,
        },
        {
          text: this.$t("_Service").toUpperCase(),
          value: "service",
          columnFilter: true,
        },
        {
          text: this.$t("_Tag").toUpperCase(),
          value: "tags",
          columnFilter: false,
        },
        {
          text: this.$t("_Activation").toUpperCase(),
          value: "isEnabled",
          align: "center",
          columnFilter: true,
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledEditDevicesActivation() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledRegister() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledOnboard() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("deleteDevice", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
