<template lang="html">
  <v-row>
    <CodeEditor
      class="mb-5 mt-0"
      :class="{ 'fix-editor-layout': fixStyle }"
      theme="light"
      font_size="15px"
      height="500px"
      min_width="100%"
      v-model="code"
    />
  </v-row>
</template>

<script>
import CodeEditor from "simple-code-editor";

export default {
  components: {
    CodeEditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    fixStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      code: this.value,
    };
  },
  watch: {
    value() {
      this.code = this.value;
    },
    code() {
      this.$emit("input", this.code);
    },
  },
};
</script>

<style scoped>
/*This style fixes the codeeditor layout problem, which is introduced by v-card-text*/
.fix-editor-layout {
  line-height: normal;
}
</style>
