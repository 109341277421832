<template>
  <div data-cy="add-swisscom-integration">
    <v-text-field
      v-model="value.as_id"
      :label="'AS ID *'"
      data-cy="tf-as-id"
      :rules="asIdRules"
    ></v-text-field>
    <v-text-field
      v-model="value.as_key"
      :label="'AS Key *'"
      data-cy="tf-as-key"
      :rules="asKeyRules"
    ></v-text-field>
    <template v-if="value.apiToken === ''">
      <v-text-field
        v-model="value.username"
        :rules="usernameRules"
        :label="$t('_Username')"
        data-cy="tf-username"
      ></v-text-field>
      <v-text-field
        v-model="value.password"
        type="password"
        :rules="passwordRules"
        :label="$t('_Password')"
        data-cy="tf-password"
      ></v-text-field>
    </template>
    <template v-if="value.username === '' && value.password === ''">
      <v-textarea
        v-model="value.apiToken"
        outlined
        :rules="apiKeyRules"
        label="API-Key"
        class="py-2"
        data-cy="tf-api-token"
      ></v-textarea>
    </template>
  </div>
</template>

<script>
import {
  asIdRules,
  asKeyRules,
  usernameRules,
  passwordRules,
  apiKeyRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          apiToken: "",
          password: "",
          username: "",
          url: "https://dx-api.thingpark.com",
          as_id: "",
          as_key: "",
        };
      },
    },
  },
  computed: {
    asIdRules() {
      return asIdRules();
    },
    asKeyRules() {
      return asKeyRules();
    },
    usernameRules() {
      return usernameRules();
    },
    passwordRules() {
      return passwordRules();
    },
    apiKeyRules() {
      return apiKeyRules();
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
