import Base from "@/data/api/Base";

export default {
  // Create
  createOrganization(organization) {
    return Base(false).http.post("/organizations", organization);
  },

  // Read
  listOrganizations() {
    return Base(false).http.get("/organizations?size=" + Base(false).maxSize);
  },
  getOrganization(organisationId) {
    return Base(true).http.get("/organizations/" + organisationId);
  },

  // Update
  updateOrganization(organization) {
    return Base(false).http.put(
      "/organizations/" + organization.id,
      organization,
    );
  },

  // Delete
  deleteOrganization(organization) {
    return Base(false).http.delete("/organizations/" + organization.id);
  },
};
