<template>
  <v-col class="py-8">
    <code
      >curl --location --request POST '{{ endpoint }}' --header 'Content-Type:
      application/json' --data-raw '{"hello": "world"}'
    </code>
  </v-col>
</template>

<script>
export default {
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    integrationSecret: {
      type: String,
      required: true,
    },
  },
  computed: {
    endpoint() {
      return (
        document.location.protocol +
        "//" +
        document.location.hostname +
        "/priamos/uplink/digimondo/" +
        this.deviceId +
        "/key/" +
        this.integrationSecret
      );
    },
  },
};
</script>
