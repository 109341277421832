<template>
  <div data-cy="base-diagnosis">
    <v-btn color="primary" @click="refresh()" data-cy="btn-refresh">
      <v-icon left dark> mdi-refresh </v-icon>{{ $t("_Refresh") }}</v-btn
    >
    <v-expansion-panels class="py-4" multiple>
      <v-expansion-panel v-for="(item, i) in items" :key="i">
        <v-expansion-panel-header
          ><v-row>
            <v-col cols="2">
              {{ diagnosis[i].time }}
            </v-col>
            <v-col cols="1">up</v-col>
            <v-spacer></v-spacer> </v-row
        ></v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-treeview
            :items="[
              {
                name: 'data',
                children: toTreeObject(diagnosis[i].message),
              },
            ]"
            open-all
            dense
          >
            <template slot="label" slot-scope="{ item }">
              <span class="blue--text text--darken-3"> {{ item.name }}: </span>
              <span v-if="item.type == 'string'" class="green--text">
                "{{ item.value }}"
              </span>
              <span
                v-if="item.type == 'number' || item.type == 'boolean'"
                class="red--text"
              >
                {{ item.value }}
              </span>
              <span
                class="grey--text text--lighten-1"
                v-if="item.type == 'Object'"
              >
                {{ "{} " + Object.keys(item.children).length + " keys" }}
              </span>
              <span
                class="grey--text text--lighten-1"
                v-if="item.type == 'Array'"
              >
                {{ "[] " + Object.keys(item.children).length + " items" }}
              </span>
            </template>
          </v-treeview>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container v-if="items.length === 0" class="text-center">
      {{ $t("_NoMessagesFound") }}
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  async mounted() {},
  computed: {
    ...mapGetters(["getLocale"]),
    diagnosis() {
      return this.items
        .map((i) => {
          var obj = {};
          obj.timeInMs = moment(i.timestamp);
          obj.time = moment(i.timestamp).format("MMMM Do YYYY, h:mm:ss a");

          obj.message = JSON.parse(i.value);
          return obj;
        })
        .sort(function (a, b) {
          return b.timeInMs - a.timeInMs;
        });
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    toTreeObject(element) {
      var keys = Object.keys(element);
      return keys.map((k) => {
        switch (typeof element[k]) {
          case "object":
            if (element[k] == null) {
              return { name: k, value: null, type: "string" };
            }
            var children = this.toTreeObject(element[k]);
            var obj = {};
            obj.name = k;
            obj.type = element[k].constructor.name;
            obj.children = children;
            return obj;
          default:
          case "string":
          case "number":
          case "boolean":
            return { name: k, value: element[k], type: typeof element[k] };
        }
      });
    },
  },
};
</script>
<style>
.v-treeview--dense .v-treeview-node__root {
  min-height: 30px;
}
</style>
