import AlarmManagement from "@/views/services/alarming/AlarmManagement.vue";
import AllAlarms from "@/views/services/alarming/alarms/AllAlarms.vue";
import AllResponsibles from "@/views/services/alarming/responsibles/AllResponsibles.vue";
import BaseResponsible from "@/views/services/alarming/responsibles/BaseResponsible.vue";
import AddResponsible from "@/views/services/alarming/responsibles/AddResponsible.vue";
import AllCustomAlarms from "@/views/services/alarming/custom-alarms/AllCustomAlarms.vue";
import CustomAlarm from "@/views/services/alarming/custom-alarms/CustomAlarm.vue";
import AddCustomAlarm from "@/views/services/alarming/custom-alarms/AddCustomAlarm.vue";
import i18n from "@/plugins/i18n";

export default {
  alarming: {
    path: "/alarming",
    name: "alarming",
    component: AlarmManagement,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
        },
      ],
    },
  },
  alarms: {
    path: "/alarming/alarms",
    name: "alarms",
    component: AllAlarms,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_AlarmsAndLogs"),
        },
      ],
    },
  },
  responsibles: {
    path: "/alarming/responsibles",
    name: "responsibles",
    component: AllResponsibles,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_Responsibles"),
        },
      ],
    },
  },
  responsible: {
    path: "/alarming/responsibles/responsible",
    name: "responsible",
    component: BaseResponsible,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Responsibles"),
          href: "/#/alarming/responsibles",
        },
        {
          name: () => i18n.t("_Responsible"),
        },
      ],
    },
  },
  addResponsible: {
    path: "/alarming/responsibles/add",
    name: "addResponsible",
    component: AddResponsible,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_Responsibles"),
          href: "/#/alarming/responsibles",
        },
        {
          name: () => i18n.t("_AddResponsible"),
        },
      ],
    },
  },
  // MPDT210068-3719: External Alarms. - Done AW
  customAlarms: {
    path: "/alarming/custom-alarms",
    name: "customAlarms",
    component: AllCustomAlarms,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_CustomAlarms"),
        },
      ],
    },
  },
  customAlarm: {
    path: "/alarming/custom-alarms/external-alarm",
    name: "customAlarm",
    component: CustomAlarm,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_CustomAlarms"),
          href: "/#/alarming/custom-alarms",
        },
        {
          name: () => i18n.t("_CustomAlarm"),
        },
      ],
    },
  },
  addCustomAlarm: {
    path: "/alarming/custom-alarms/add",
    name: "addCustomAlarm",
    component: AddCustomAlarm,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Alarming"),
          href: "/#/alarming",
        },
        {
          name: () => i18n.t("_CustomAlarms"),
          href: "/#/alarming/custom-alarms",
        },
        {
          name: () => i18n.t("_AddCustomAlarm"),
        },
      ],
    },
  },
};
