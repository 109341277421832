<template>
  <v-row justify="center">
    <BaseTile :title="$t('_AlarmsAndLogs')" path="/alarming/alarms" />
    <BaseTile :title="$t('_Responsibles')" path="/alarming/responsibles" />
    <!-- MPDT210068-3719 -->
    <BaseTile :title="$t('_CustomAlarms')" path="/alarming/custom-alarms" />
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
