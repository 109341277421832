<!-- MPDT210068-3719 -->
<template>
  <BaseContentLoader :title="$t('_Responsible')" :isLoading="isLoading">
    <template slot="content" v-if="newResponsible !== undefined">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newResponsible"
          :items="summaryItems"
          isEditable
        ></BaseSummary>
      </v-form>

      <v-col cols="5">
        <v-card-title class="mx-n4">{{ $t("_Organization") }} </v-card-title>
        <v-chip-group
          v-model="activeOrganizationPlan"
          color="primary"
          multiple
          class="py-2"
        >
          <v-chip
            :key="getCurrentOrganization.id"
            filter
            label
            pill
            :value="getCurrentOrganization.id"
          >
            <template slot="default">{{
              getCurrentOrganization.name
            }}</template>
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-row class="px-3">
        <template v-for="plan in plans">
          <v-col
            v-if="activeOrganizationPlan.includes(plan.id)"
            cols="6"
            :key="plan.id"
          >
            <v-card class="my-2">
              <v-card-title>{{
                `${$t("_ResponsibilityPlan")}: ${plan.name}`
              }}</v-card-title>
              <v-card-text>
                <v-card-subtitle>{{ $t("_ServiceTime") }}</v-card-subtitle>
                <template v-if="plan.time !== undefined">
                  <v-row
                    v-for="day in weekdays"
                    :key="day"
                    class="px-4 mt-n6"
                    align="center"
                  >
                    <v-col cols="4"
                      ><span class="text-right text-subtitle-2">{{
                        day
                      }}</span></v-col
                    >
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].start"
                      ></BaseTimePicker
                    ></v-col>
                    <v-col cols="1">-</v-col>
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].end"
                      ></BaseTimePicker
                    ></v-col>
                  </v-row>
                </template>
                <v-divider class="mt-4 py-4"></v-divider>
                <v-card-subtitle>{{ $t("_AlarmType") }}</v-card-subtitle>
                <v-chip-group
                  v-model="plan.alarmTypes"
                  :label="$t('_Services') + ' *'"
                  color="primary"
                  class="px-4"
                  multiple
                >
                  <v-chip
                    v-for="alarmType in alarmTypes"
                    :key="alarmType"
                    filter
                    pill
                    :value="alarmType"
                  >
                    <template slot="default">{{ alarmType }}</template>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card></v-col
          >
        </template>
      </v-row>

      <v-divider class="mt-6 py-4"></v-divider>

      <v-col cols="5">
        <v-card-title class="mx-n4">{{ $t("_Services") }} </v-card-title>
        <v-chip-group
          v-model="activeServicePlans"
          color="primary"
          multiple
          class="py-2"
        >
          <v-chip
            v-for="service in getServices"
            :key="service.id"
            filter
            label
            pill
            :value="service.id"
          >
            <template slot="default">{{ service.name }}</template>
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-row class="px-3">
        <template v-for="plan in plans">
          <v-col
            v-if="activeServicePlans.includes(plan.id)"
            cols="6"
            :key="plan.id"
          >
            <v-card class="my-2">
              <v-card-title>{{
                `${$t("_ResponsibilityPlan")}: ${plan.name}`
              }}</v-card-title>
              <v-card-text>
                <v-card-subtitle>{{ $t("_ServiceTime") }}</v-card-subtitle>
                <template v-if="plan.time !== undefined">
                  <v-row
                    v-for="day in weekdays"
                    :key="day"
                    class="px-4 mt-n6"
                    align="center"
                  >
                    <v-col cols="4"
                      ><span class="text-right text-subtitle-2">{{
                        day
                      }}</span></v-col
                    >
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].start"
                      ></BaseTimePicker
                    ></v-col>
                    <v-col cols="1">-</v-col>
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].end"
                      ></BaseTimePicker
                    ></v-col>
                  </v-row>
                </template>
                <v-divider class="mt-4 py-4"></v-divider>
                <v-card-subtitle>{{ $t("_AlarmType") }}</v-card-subtitle>
                <v-chip-group
                  v-model="plan.alarmTypes"
                  :label="$t('_Services') + ' *'"
                  color="primary"
                  class="px-4"
                  multiple
                >
                  <v-chip
                    v-for="alarmType in alarmTypes"
                    :key="alarmType"
                    filter
                    pill
                    :value="alarmType"
                  >
                    <template slot="default">{{ alarmType }}</template>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card></v-col
          >
        </template>
      </v-row>

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}</v-btn
        >
        <v-btn
          class="mx-3"
          @click="saveChanges()"
          :disabled="disabledSave"
          color="primary"
          >{{ $t("_SaveChanges") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import moment from "moment/moment";

export default {
  data() {
    return {
      isLoading: true,
      isValid: false,
      newResponsible: undefined,
      oldPlans: undefined,
      activeOrganizationPlan: [],
      activeServicePlans: [],
      plans: [],
    };
  },

  async mounted() {
    try {
      await this.$store.dispatch("loadServices");
      this.newResponsible = cloneDeep(
        await this.$store.dispatch("loadResponsible", this.$route.query.id),
      );
      this.activeServicePlans = this.newResponsible.plans
        .map((s) => s.id)
        .filter((p) => this.getServices.map((s) => s.id).includes(p));

      this.plans = this.getServices.map((s) => {
        let obj = { id: s.id, name: s.name };

        let plan = this.newResponsible.plans.find((p) => p.id === s.id);
        if (plan === undefined) {
          obj.alarmTypes = ["FAILURE"];
          obj.time = moment.weekdays().reduce(function (map, obj) {
            map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
            return map;
          }, {});
        } else {
          obj.alarmTypes = plan.alarmTypes;
          obj.time = plan.time;
        }
        return obj;
      });
      this.activeOrganizationPlan = this.newResponsible.plans
        .filter((p) => this.getCurrentOrganization.id === p.id)
        .map((s) => s.id);
      if (
        this.activeOrganizationPlan.includes(this.getCurrentOrganization.id)
      ) {
        this.plans.push(
          Object.assign(
            { name: this.getCurrentOrganization.name },
            this.newResponsible.plans.find(
              (p) => p.id === this.getCurrentOrganization.id,
            ),
          ),
        );
      } else {
        this.plans.push({
          id: this.getCurrentOrganization.id,
          name: this.getCurrentOrganization.name,
          alarmTypes: ["FAILURE"],
          time: moment.weekdays().reduce(function (map, obj) {
            map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
            return map;
          }, {}),
        });
      }

      this.oldPlans = Object.assign({}, this.plans);
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getResponsibleById",
      "isSystemAdmin",
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "getServices",
    ]),
    responsible() {
      return this.getResponsibleById(this.$route.query.id);
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newResponsible == undefined) return true;
      if (this.responsible == undefined) return true;
      if (!this.isValid) return true;

      return false;
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newResponsible == undefined) return true;
      if (this.responsible == undefined) return true;

      return false;
    },
    alarmTypes() {
      return ["FAILURE", "WARNING"];
    },
    weekdays() {
      return Object.values(moment.weekdays());
    },

    summaryItems() {
      return [
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "email",
          name: this.$t("_Email"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
      ];
    },
  },
  methods: {
    undoChanges() {
      this.newResponsible = cloneDeep(this.responsible);
      this.activeServicePlans = this.newResponsible.plans
        .map((s) => s.id)
        .filter((p) => this.getServices.map((s) => s.id).includes(p));
      this.plans = this.getServices.map((s) => {
        let obj = { id: s.id, name: s.name };

        let plan = this.newResponsible.plans.find((p) => p.id === s.id);
        if (plan === undefined) {
          obj.alarmTypes = ["FAILURE"];
          obj.time = moment.weekdays().reduce(function (map, obj) {
            map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
            return map;
          }, {});
        } else {
          obj.alarmTypes = plan.alarmTypes;
          obj.time = plan.time;
        }
        return obj;
      });

      this.activeOrganizationPlan = this.newResponsible.plans
        .filter((p) => this.getCurrentOrganization.id === p.id)
        .map((s) => s.id);
      if (
        this.activeOrganizationPlan.includes(this.getCurrentOrganization.id)
      ) {
        this.plans.push(
          Object.assign(
            { name: this.getCurrentOrganization.name },
            this.newResponsible.plans.find(
              (p) => p.id === this.getCurrentOrganization.id,
            ),
          ),
        );
      } else {
        this.plans.push({
          id: this.getCurrentOrganization.id,
          name: this.getCurrentOrganization.name,
          alarmTypes: ["FAILURE"],
          time: moment.weekdays().reduce(function (map, obj) {
            map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
            return map;
          }, {}),
        });
      }
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        const payload = {
          id: this.newResponsible.id,
          name: this.newResponsible.name,
          email: this.newResponsible.email,
          organizationId: this.newResponsible.organizationId,
          plans: this.plans
            .filter(
              (p) =>
                this.activeServicePlans.includes(p.id) ||
                this.activeOrganizationPlan.includes(p.id),
            )
            .map((p) =>
              Object({
                alarmTypes: p.alarmTypes,
                id: p.id,
                time: p.time,
              }),
            ),
        };
        await this.$store.dispatch("updateResponsible", payload);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
