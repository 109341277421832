export const countries = {
  EG: {
    name: "Egypt",
    subsidiary: "PHOENIX CONTACT Egypt",
    street: "Banking Road from 90th Street",
    houseNumber: "Office No. 132, 133 1st Floor",
    city: "Cairo",
    zipCode: "", // TODO: set correct zip code
  },
  AU: {
    name: "Australia",
    subsidiary: "PHOENIX CONTACT Pty. Ltd",
    street: "South Street",
    houseNumber: "Unit 7, 2-8",
    city: "Rydalmere NSW",
    zipCode: "2116",
  },
  BE: {
    name: "Belgium",
    subsidiary: "PHOENIX CONTACT nv/sa",
    street: "Minervastraat",
    houseNumber: "10-12",
    city: "Zaventem Keiberg II",
    zipCode: "1930",
  },
  BR: {
    name: "Brasil",
    subsidiary: "PHOENIX CONTACT Indústria e Comércio Limitada",
    street: "Avenida das Nações Unidas",
    houseNumber: "11.541 suites 191, 192",
    city: "Sao Paulo - SP",
    zipCode: "04578-000",
  },
  CL: {
    name: "Chile",
    subsidiary: "PHOENIX CONTACT S.A.",
    street: "Calle Nueva",
    houseNumber: "1661-G",
    city: "Huechuraba, Santiago",
    zipCode: "8580000",
  },
  CN: {
    name: "China",
    subsidiary: "PHOENIX CONTACT China Holding Co. Ltd.",
    street: "Phoenix Road Jiangning Development Zone",
    houseNumber: "36",
    city: "Nanjing",
    zipCode: "211100",
  },
  DE: {
    name: "Germany",
    subsidiary: "PHOENIX CONTACT Deutschland GmbH",
    street: "Flachsmarktstraße",
    houseNumber: "8",
    city: "Blomberg",
    zipCode: "32825",
  },
  DK: {
    name: "Denmark",
    subsidiary: "PHOENIX CONTACT A/S",
    street: "Hammerholmen",
    houseNumber: "43-45",
    city: "Hvidovre",
    zipCode: "2650",
  },
  FI: {
    name: "Finland",
    subsidiary: "PHOENIX CONTACT Oy",
    street: "Niittytie",
    houseNumber: "11",
    city: "Vaantaa",
    zipCode: "01300",
  },
  FR: {
    name: "France",
    subsidiary: "PHOENIX CONTACT SAS",
    street: "Bd de Baubourg",
    houseNumber: "52",
    city: "Marne La Valee Cedex 2",
    zipCode: "77436",
  },
  GB: {
    name: "Great Britain",
    subsidiary: "PHOENIX CONTACT Ltd.",
    street: "Halesfield",
    houseNumber: "13",
    city: "Telford Shropshire",
    zipCode: "PF7-P4G",
  },
  IN: {
    name: "India",
    subsidiary: "PHOENIX CONTACT INDIA PRIVATE Ltd.",
    street: "Okhla Industrial Area Phase - II",
    houseNumber: "F-26/2",
    city: "New Delhi",
    zipCode: "110 020",
  },
  ID: {
    name: "Indonesia",
    subsidiary: "PTT Phoenix Contact",
    street: "Prominence Office Tower Alam Sutera",
    houseNumber: "17th. Floor, Unit A",
    city: "Banten",
    zipCode: "15143",
  },
  IE: {
    name: "Ireland",
    subsidiary: "PHOENIX CONTACT IRELAND Ltd.",
    street: "The Exchange Calmount Park Ballymount",
    houseNumber: "C6",
    city: "Dublin",
    zipCode: "12 XE18",
  },
  IL: {
    name: "Israel",
    subsidiary: " PHOENIX CONTACT (ISRAEL) Ltd.",
    street: "Industrial Park Hasharon",
    houseNumber: "P.O. Box 1799",
    city: "Qadima",
    zipCode: "60920",
  },
  IT: {
    name: "Italy",
    subsidiary: "PHOENIX CONTACT SpA",
    street: "Via Bellini",
    houseNumber: "39/41",
    city: "Cusano Milanino Mi",
    zipCode: "20095",
  },
  JP: {
    name: "Japan",
    subsidiary: "PHOENIX CONTACT K.K.",
    street: "Yusen Shinyokohama 1-chome",
    houseNumber: "Bldg. 6F, 7-9",
    city: "Yokohama",
    zipCode: "222-0033",
  },
  CA: {
    name: "Canada",
    subsidiary: "PHOENIX CONTACT Ltd.",
    street: "Parkhill",
    houseNumber: "8240",
    city: "Milton",
    zipCode: "ON L9T 5V7",
  },
  KZ: {
    name: "Kazakhstan",
    subsidiary: "TOO Phoenix Contact Kazakhstan",
    street: "Masanchi str.",
    houseNumber: "office 42 98A",
    city: "Almaty",
    zipCode: "050022",
  },
  CO: {
    name: "Colombia",
    subsidiary: "PHOENIX CONTACT SAS",
    street: "", // TODO: set correct street and house number
    houseNumber: "",
    city: "Bogota",
    zipCode: "111071",
  },
  HR: {
    name: "Croatia",
    subsidiary: "PHOENIX CONTACT d.o.o.",
    street: "Capraška",
    houseNumber: "6",
    city: "Zagreb",
    zipCode: "10000",
  },
  LT: {
    name: "Lithuania",
    subsidiary: "PHOENIX CONTACT UAB",
    street: "Švitrigailos",
    houseNumber: "11b",
    city: "Vilnius",
    zipCode: "03228",
  },
  LU: {
    name: "Luxembourg",
    subsidiary: "PHOENIX CONTACT s.à r.l",
    street: "op Bourmnicht",
    houseNumber: "10a",
    city: "Bertrange",
    zipCode: "8070",
  },
  MY: {
    name: "Malaysia",
    subsidiary: "PHOENIX CONTACT (Malaysia) Sdn. Bhd.",
    street: "Jalan Technologi 3/4, PJU 5 Taman Sains Selangor",
    houseNumber: "1",
    city: "Petaling Jaya Selangor",
    zipCode: "47810",
  },
  MA: {
    name: "Morocco",
    subsidiary: "PHOENIX CONTACT SARL",
    street: "Lotissement la Colline II",
    houseNumber: "Lot 37-38 Immeuble B 1er Etage",
    city: "Sidi Maarouf - Casablance",
    zipCode: "Maroc 20190",
  },
  MX: {
    name: "Mexico",
    subsidiary: "PHOENIX CONTACT S.A. de C.V.",
    street: "Lago Alberto",
    houseNumber: "Piso 9",
    city: "Col. Granada",
    zipCode: "11520 Ciudad de Mexico",
  },
  NZ: {
    name: "New Zealand",
    subsidiary: "PHOENIX CONTACT Limited",
    street: "Great South Road",
    houseNumber: "Unit 15C, 906-930",
    city: "Penrose, Oakland",
    zipCode: "1061",
  },
  NL: {
    name: "Netherlands",
    subsidiary: "PHOENIX CONTACT B.V.",
    street: "Hengelder",
    houseNumber: "56",
    city: "Pa Zevenaar",
    zipCode: "6902",
  },
  NO: {
    name: "Norway",
    subsidiary: "PHOENIX CONTACT AS",
    street: "Strømsveien",
    houseNumber: "344",
    city: "Oslo",
    zipCode: "1081",
  },
  AT: {
    name: "Austria",
    subsidiary: "PHOENIX CONTACT GmbH",
    street: "Ada-Christen-Gasse",
    houseNumber: "4",
    city: "Wien",
    zipCode: "1100",
  },
  PL: {
    name: "Poland",
    subsidiary: "PHOENIX CONTACT Sp. z o. o.",
    street: "Bierutowska",
    houseNumber: "57-59",
    city: "WROCŁAW",
    zipCode: "51-317",
  },
  PT: {
    name: "Portugal",
    subsidiary: "PHOENIX CONTACT S.A.",
    street: "Sintra Business Park",
    houseNumber: "Edificio 1 Zona Industrial da Abrunheirea",
    city: "Sintra",
    zipCode: "2710-089",
  },
  RO: {
    name: "Romania",
    subsidiary: "PHOENIX CONTACT SRL",
    street: "Splaiul Unirii",
    houseNumber: "165",
    city: "Bucaresti Sector 3",
    zipCode: "030133",
  },
  SA: {
    name: "Saudi Arabia",
    subsidiary: "PHOENIX CONTACT Trading LLC",
    street: "King Fahed Branch Road",
    houseNumber: "Office M49+M62 Mezzanine Floor, Moon Tower",
    city: "Riyadh",
    zipCode: "11321",
  },
  SE: {
    name: "Sweden",
    subsidiary: "PHOENIX CONTACT AB",
    street: "Linvägen",
    houseNumber: "2",
    city: "Huddinge",
    zipCode: "14144",
  },
  CH: {
    name: "Switzerland",
    subsidiary: "PHOENIX CONTACT AG",
    street: "Zürcherstrasse",
    houseNumber: "22",
    city: "Tagelswangen",
    zipCode: "8317",
  },
  SG: {
    name: "Singapore",
    subsidiary: "PHOENIX CONTACT Sea Pte. Ltd.",
    street: "Bendemeer Road",
    houseNumber: "80, Level 10, Room 02",
    city: "Singapore",
    zipCode: "339949",
  },
  SK: {
    name: "Slovakia",
    subsidiary: "PHOENIX CONTACT s.r.o.",
    street: "Námestie Mateja Korvina",
    houseNumber: "1",
    city: "Bratislava",
    zipCode: "811 07",
  },
  ES: {
    name: "Spain",
    subsidiary: "PHOENIX CONTACT S.A.U",
    street: "Parque Tecnológico de Asturias",
    houseNumber: "16-17",
    city: "Llanera (Asturias)",
    zipCode: "33428",
  },
  ZA: {
    name: "South Africa",
    subsidiary: "PHOENIX CONTACT (Pty) Ltd",
    street: "Lyn Road",
    houseNumber: "36, P.O. Box 916",
    city: "Ferndale",
    zipCode: "2160",
  },
  KR: {
    name: "South Korea",
    subsidiary: "PHOENIX CONTACT Ltd", // TODO: correct address has to be set
    street: "",
    houseNumber: "",
    city: "",
    zipCode: "",
  },
  TW: {
    name: "Taiwan Republic of China",
    subsidiary: "PHOENIX CONTACT Co. Ltd.",
    street: "Sanchong Road",
    houseNumber: "Suite B, 3F, No. 66",
    city: "Taipei",
    zipCode: "11560",
  },
  TH: {
    name: "Thailand",
    subsidiary: "PHOENIX CONTACT (Thailand) Co. Ltd.",
    street: "Rama 9 Road, Bang Kapi Subdistrict",
    houseNumber: "Buildung 797, 797/1, 1st and 2nd floor",
    city: "Bangkok",
    zipCode: "10310",
  },
  CZ: {
    name: "Czech Republic",
    subsidiary: "PHOENIX CONTACT s.r.o",
    street: "Dornych",
    houseNumber: "54/47",
    city: "Brno",
    zipCode: "61700",
  },
  TR: {
    name: "Turkey",
    subsidiary: "PHOENIX CONTACT Elektronik Ticaret Limited Sirketi",
    street: "Hanim Seti Sokak",
    houseNumber: "38A",
    city: "Istanbul",
    zipCode: "34692",
  },
  UA: {
    name: "Ukraine",
    subsidiary: "TOV PHOENIX CONTACT",
    street: "Okhtyrskyi lane, 7, block 3",
    houseNumber: "Office 3-203",
    city: "Kyiv",
    zipCode: "03066",
  },
  HU: {
    name: "Hungary",
    subsidiary: "PHOENIX CONTACT Kft.",
    street: "Csörsz utca",
    houseNumber: "49-51",
    city: "Budapest",
    zipCode: "1124",
  },
  US: {
    name: "US",
    subsidiary: "PHOENIX CONTACT USA Inc.",
    street: "Fulling Mill Road",
    houseNumber: "586",
    city: "Middletown PA",
    zipCode: "17075",
  },
  AE: {
    name: "United Arab Emirates",
    subsidiary: "PHOENIX CONTACT Middle East FZ LLC",
    street: "Dubai Science Park",
    houseNumber: "1201N, Floor 12",
    city: "Dubai, United Emirates",
    zipCode: "",
  },
};
