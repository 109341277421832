import DadManagement from "@/views/services/dad/DadManagement.vue";
import AllIntegrations from "@/views/services/dad/integrations/AllIntegrations.vue";
import BaseIntegration from "@/views/services/dad/integrations/BaseIntegration.vue";
import AddIntegration from "@/views/services/dad/integrations/AddIntegration.vue";
import AllDevices from "@/views/services/dad/devices/AllDevices.vue";
import BaseDevice from "@/views/services/dad/devices/BaseDevice.vue";
import EditDevicesActivation from "@/views/services/dad/devices/EditDevicesActivation.vue";
import ExportDeviceData from "@/views/services/dad/devices/ExportDeviceData.vue";
import RegisterDevices from "@/views/services/dad/devices/RegisterDevices.vue";
import OnboardDevices from "@/views/services/dad/devices/OnboardDevices.vue";
import AllDeviceGroups from "@/views/services/dad/device-groups/AllDeviceGroups.vue";
import BaseDeviceGroup from "@/views/services/dad/device-groups/DeviceGroup.vue";
import AddDeviceGroup from "@/views/services/dad/device-groups/AddDeviceGroup.vue";
import i18n from "@/plugins/i18n";
import DataSharing from "@/views/services/dad/sharing/DataSharing.vue";
import AddSharing from "@/views/services/dad/sharing/AddSharing.vue";
import BaseSharing from "@/views/services/dad/sharing/BaseSharing";

export default {
  devicesAndData: {
    path: "/dad",
    name: "dad",
    component: DadManagement,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
        },
      ],
    },
  },
  integrations: {
    path: "/dad/integrations",
    name: "integrations",
    component: AllIntegrations,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Integrations"),
        },
      ],
    },
  },
  integration: {
    path: "/dad/integrations/integration",
    name: "integration",
    component: BaseIntegration,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Integrations"),
          href: "/#/dad/integrations",
        },
        {
          name: () => i18n.t("_Integration"),
        },
      ],
    },
  },
  addIntegration: {
    path: "/dad/integrations/add",
    name: "addIntegration",
    component: AddIntegration,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Integrations"),
          href: "/#/dad/integrations",
        },
        {
          name: () => i18n.t("_AddIntegration"),
        },
      ],
    },
  },
  devices: {
    path: "/dad/devices",
    name: "devices",
    component: AllDevices,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
        },
      ],
    },
  },
  device: {
    path: "/dad/devices/device",
    name: "device",
    component: BaseDevice,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
          href: "/#/dad/devices",
        },
        {
          name: () => i18n.t("_Device"),
        },
      ],
    },
  },
  devicesActivation: {
    path: "/dad/devices/activation",
    name: "devicesActivation",
    component: EditDevicesActivation,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
          href: "/#/dad/devices",
        },
        {
          name: () => i18n.t("_EditDevicesActivation"),
        },
      ],
    },
  },
  registerDevices: {
    path: "/dad/devices/register",
    name: "registerDevices",
    component: RegisterDevices,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
          href: "/#/dad/devices",
        },
        {
          name: () => i18n.t("_RegisterDevices"),
        },
      ],
    },
  },
  onboardDevices: {
    path: "/dad/devices/onboard",
    name: "onboardDevices",
    component: OnboardDevices,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
          href: "/#/dad/devices",
        },
        {
          name: () => i18n.t("_OnboardDevices"),
        },
      ],
    },
  },
  exportDeviceData: {
    path: "/dad/devices/device/export",
    name: "exportDeviceData",
    component: ExportDeviceData,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_Devices"),
          href: "/#/dad/devices",
        },
        {
          name: () => i18n.t("_Device"),
        },
        {
          name: () => i18n.t("_ExportData"),
        },
      ],
    },
  },
  deviceGroups: {
    path: "/dad/deviceGroups",
    name: "deviceGroups",
    component: AllDeviceGroups,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DeviceGroups"),
        },
      ],
    },
  },
  deviceGroup: {
    path: "/dad/deviceGroups/deviceGroup",
    name: "deviceGroup",
    component: BaseDeviceGroup,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DeviceGroups"),
          href: "/#/dad/deviceGroups",
        },
        {
          name: () => i18n.t("_DeviceGroup"),
        },
      ],
    },
  },
  addDeviceGroup: {
    path: "/dad/deviceGroups/add",
    name: "addDeviceGroup",
    component: AddDeviceGroup,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DeviceGroups"),
          href: "/#/dad/deviceGroups",
        },
        {
          name: () => i18n.t("_AddDeviceGroup"),
        },
      ],
    },
  },
  sharing: {
    path: "/dad/datasharing",
    name: "dataSharing",
    component: DataSharing,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DataSharings"),
        },
      ],
    },
  },
  addSharing: {
    path: "/dad/datasharing/add",
    name: "addDataSharing",
    component: AddSharing,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DataSharings"),
          href: "/#/dad/datasharing",
        },
        {
          name: () => i18n.t("_AddDataSharing"),
        },
      ],
    },
  },
  baseSharing: {
    path: "/dad/datasharing/sharing",
    name: "baseSharing",
    component: BaseSharing,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_DevicesAndData"),
          href: "/#/dad",
        },
        {
          name: () => i18n.t("_DataSharings"),
          href: "/#/dad/datasharing",
        },
        {
          name: () => i18n.t("_DataSharing"),
        },
      ],
    },
  },
};
