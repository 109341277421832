import axios from "axios";
import store from "@/store.js";

function base(
  inOrganization = true,
  inBilling = false,
  authorized = true,
  apiBase = "/priamos/api",
  host = "",
) {
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authorized) {
    var token = localStorage.getItem("pxce-token");
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (inBilling) {
    headers["X-Billing-Token"] =
      store.getters.getBillingAccessToken.access_token;
  }

  if (inOrganization) {
    headers["X-Organization-Id"] = store.getters.getCurrentOrganization.id;
  }

  return {
    http: produceHttp(headers, apiBase, host),
    maxSize: 1000000,
  };
}

export default base;

function produceHttp(headers, apiBase, host) {
  var http = axios.create({
    baseURL: host + apiBase,
    withCredentials: false,
    headers: headers,
  });

  http.interceptors.response.use(function (response) {
    return response;
  }, errorHandler);

  return http;
}

function errorHandler(error) {
  store.commit("setErrorFlag", true);
  if (error.response) {
    if (error.response.status >= 400 && error.response.status < 502) {
      store.commit("setErrorTitle", "Oops, something went wrong!");
      store.commit("setErrorText", error.response.data.message);

      return Promise.reject(error);
    }
    if (error.response.status >= 502 && error.response.status < 600) {
      store.commit("setErrorTitle", "Oops, something went wrong!");
      store.commit(
        "setErrorText",
        "Please try again later or contact your service admin",
      );
      return Promise.reject(error);
    }
  }
}
