<template>
  <div>
    <v-text-field
      v-model="value.nwAdr"
      :label="$t('_MulticastAddress') + ' *'"
      :rules="nwAdrRules"
      data-cy="tf-nw-adr"
      counter="8"
    >
      <template slot="append">
        <v-btn icon @click="randomNwAdr()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
    <v-text-field
      v-model="value.nwSKey"
      :label="$t('_MulticastNetworkSessionKey') + ' *'"
      :rules="nwSKeyRules"
      data-cy="tf-nw-s-key"
      counter="32"
    >
      <template slot="append">
        <v-btn icon @click="randomNwSKey()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
    <v-text-field
      v-model="value.appSKey"
      :label="$t('_MulticastApplicationSessionKey') + ' *'"
      :rules="appSKeyRules"
      data-cy="tf-app-s-key"
      counter="32"
    >
      <template slot="append">
        <v-btn icon @click="randomAppSKey()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
  </div>
</template>

<script>
import { genHexString } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nwAdrRules,
  nwSKeyRules,
  appSKeyRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return { nwAdr: "", nwSKey: "", appSKey: "" };
      },
    },
  },
  computed: {
    nwAdrRules() {
      return nwAdrRules();
    },
    nwSKeyRules() {
      return nwSKeyRules();
    },
    appSKeyRules() {
      return appSKeyRules();
    },
  },
  methods: {
    randomNwAdr() {
      this.value.nwAdr = genHexString(8).toUpperCase();
      this.$emit("input", this.value);
    },
    randomNwSKey() {
      this.value.nwSKey = genHexString(32).toUpperCase();
      this.$emit("input", this.value);
    },
    randomAppSKey() {
      this.value.appSKey = genHexString(32).toUpperCase();
      this.$emit("input", this.value);
    },
  },
  watch: {
    value: {
      handler() {
        this.value.nwAdr = this.value.nwAdr.toUpperCase();
        this.value.nwSKey = this.value.nwSKey.toUpperCase();
        this.value.appSKey = this.value.appSKey.toUpperCase();

        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
