<!-- Dynamic key and values for http headers -->
<template>
  <div class="pb-4">
    <v-row class="px-3" v-for="(element, index) in filledFields" :key="index">
      <!-- Keys -->
      <v-col cols="5">
        <v-text-field v-model="element.key" label="Key"> </v-text-field>
      </v-col>

      <!-- Spacer -->
      <v-col cols="1">
        <v-spacer></v-spacer>
      </v-col>

      <!-- Values -->
      <v-col cols="5">
        <v-text-field v-model="element.value" label="Value"> </v-text-field>
      </v-col>
      <!-- Actions -->
      <v-col cols="1">
        <v-btn icon @click="remove(index)">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="px-3">
      <v-col cols="10">
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="2">
        <v-btn @click="add()"> {{ $t("_AddFields") }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  mounted() {
    let keys = Object.keys(this.value);

    this.filledFields = keys.map((key) => {
      return { key: key, value: this.value[key] };
    });
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      filledFields: [],
    };
  },
  methods: {
    add() {
      this.filledFields.push({ key: "", value: "" });
    },
    remove(index) {
      this.filledFields.splice(index, 1);
    },
  },
  watch: {
    filledFields: {
      handler: function () {
        let val = this.filledFields.reduce(function (map, obj) {
          map[obj.key] = obj.value;
          return map;
        }, {});

        this.$emit("input", val);
      },
      deep: true,
    },
  },
};
</script>
