<!-- MPDT210068-3719 -->
<template>
  <BaseTabDialog
    :subHeaders="subHeaders"
    :tabHeaders="tabHeaders"
    @confirm="addCustomAlarm()"
    @cancel="$router.push('/alarming/custom-alarms')"
  >
    <template slot="tab-item-0">
      <v-col>
        <BaseTable
          v-model="selectedDevice"
          :headers="tableHeaders"
          :items="tableItems"
          show-select
          item-key="deviceId"
          single-select
          :rules="tableRules"
        />
      </v-col>
    </template>

    <template slot="tab-item-1">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="title"
            :label="$t('_Title') + ' *'"
            data-cy="tf-title"
            :rules="titleRules"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="alarmType"
            :label="$t('_AlarmType') + ' *'"
            :items="alarmTypes"
            data-cy="sel-alarmTypes"
            :rules="alarmTypeRules"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-textarea
            v-model="description"
            :label="$t('_Description') + ' *'"
            :rules="descriptionRules"
            data-cy="ta-description"
            outlined
          />

          <v-card-subtitle>{{ $t("_Condition") }}</v-card-subtitle>
          <CodeEditor
            class="fix-editor-layout mb-6"
            theme="light"
            font_size="15px"
            :copy_code="false"
            min_width="100%"
            v-model="testData"
            @change="executionSuccess = false"
          />
          <CodeEditor
            class="fix-editor-layout mb-6 github_dark"
            font_size="15px"
            theme="light"
            :copy_code="false"
            min_width="100%"
            v-model="code"
            @change="executionSuccess = false"
          />
          <v-input
            :value="executionSuccess"
            :rules="codeRules"
            style="visibility: hidden"
          >
          </v-input>
          <v-row justify="space-between">
            <v-spacer></v-spacer>
            <v-btn
              class="mx-3 mb-6"
              :disabled="executionSuccess"
              color="primary"
              @click="tryExecute()"
            >
              {{ this.$t("_TryExecution") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </BaseTabDialog>
</template>
<script>
import { mapGetters } from "vuex";
import {
  delay,
  alarmTypes,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  titleRules,
  alarmTypeRules,
  descriptionRules,
  tableRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";

import CodeEditor from "simple-code-editor";
import CustomAlarmsApi from "@/data/api/CustomAlarmsApi";

export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      isLoading: true,
      selectedDevice: [],
      title: "",
      description: "",
      alarmType: "",
      testData:
        "\n" +
        "// Use this to simulate device up event\n" +
        "var message = {\n" +
        '    "deviceId": "<DEVICE_ID>", \n' +
        '    "data": {\n' +
        '      "alarm": true \n' +
        "    }\n" +
        "}\n",
      code:
        "\n" +
        "// If the result of this function returns 'true' it will trigger an alarm.\n" +
        "function triggerAlarm(message){\n" +
        "    if(message.data.alarm) {\n" +
        "       return true;\n" +
        "    }\n" +
        "    return false;\n" +
        "}\n" +
        "\n" +
        "\n" +
        "// If the result of this function returns 'true' it will resolve the alarm \n" +
        "function resolveAlarm(message){\n" +
        "    if(!message.data.alarm) {\n" +
        "       return true;\n" +
        "    }\n" +
        "    return false;\n" +
        "}\n",
      executionSuccess: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadIntegrations");
      await this.$store.dispatch("loadDevices");
      await this.$store.dispatch("loadCustomAlarms");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getAllDevices",
      "getAllCustomAlarms",
      "getIntegrationById",
    ]),
    subHeaders() {
      return [
        this.$t("__CFA1_AddCustomAlarms"),
        this.$t("__CFA2_AddCustomAlarms"),
      ];
    },
    tabHeaders() {
      return [this.$t("_SelectDevice"), this.$t("_Definitions")];
    },
    tableItems() {
      return this.getAllDevices.map((d) => {
        let obj = Object.assign({}, d);
        obj.integrationName = this.getIntegrationById(d.integrationId).name;
        obj.type = `${d.details.vendor} ${d.details.deviceType} ${d.details.firmware}`;
        return obj;
      });
    },
    tableHeaders() {
      return [
        { text: this.$t("_Name").toUpperCase(), value: "name" },
        { text: this.$t("_DeviceId").toUpperCase(), value: "deviceId" },
        { text: this.$t("_Type").toUpperCase(), value: "type" },
        {
          text: this.$t("_Integration").toUpperCase(),
          value: "integrationName",
        },
      ];
    },
    alarmTypes: () => alarmTypes.concat("FAILURE").filter((a) => a !== "ERROR"),
    tableRules() {
      return tableRules();
    },
    titleRules() {
      return titleRules();
    },
    alarmTypeRules() {
      return alarmTypeRules();
    },
    descriptionRules() {
      return descriptionRules();
    },
    codeRules() {
      return [(v) => v || "Error"];
    },
  },
  methods: {
    async tryExecute() {
      try {
        const payload = {
          code: this.testData + this.code,
        };
        await CustomAlarmsApi.tryExecuteCode(payload);
        this.executionSuccess = true;
      } catch (err) {
        console.log(err);
      }
    },
    async addCustomAlarm() {
      this.isLoading = true;
      try {
        const payload = {
          title: this.title,
          description: this.description,
          alarmType: this.alarmType,
          monitorId: this.selectedDevice[0].id,
          organizationId: this.getCurrentOrganization.id,
          code: this.code,
          testData: this.testData,
        };
        await delay(500);
        await this.$store.dispatch("createNewCustomAlarm", payload);
        await this.$router.push("/alarming/custom-alarms");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    code: {
      handler: function () {
        this.executionSuccess = false;
      },
    },
    testData: {
      handler: function () {
        this.executionSuccess = false;
      },
    },
  },
};
</script>

<style scoped>
/*This style fixes the codeeditor layout problem, which is introduced by v-card-text*/
.fix-editor-layout {
  line-height: normal;
}
</style>
