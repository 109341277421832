import Vue from "vue";
import Router from "vue-router";

import Alarming from "./services/alarming/Alarming.js";
import Dad from "./services/dad/Dad.js";
import Home from "./services/home/Home.js";
import LicenceManagement from "./services/licences/Licences.js";
import Organization from "./services/organization/Organization.js";
import Settings from "./services/settings/Settings.js";
import System from "./services/system/System.js";

//import store from "@/store.js";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const routes = {
  ...Alarming,
  ...Dad,
  ...Home,
  ...LicenceManagement,
  ...Organization,
  ...Settings,
  ...System,
};

const router = new Router({
  //  mode: "history",
  linkActiveClass: "is-active",
  fullRoutes: routes,
  routes: Object.values(routes),
});

export default router;
