<template>
  <div data-cy="add-rest-integration">
    <v-row class="px-3" align="baseline">
      <v-text-field
        v-model="value.integrationSecret"
        :label="$t('_ClientSecret') + ' *'"
        :rules="clientSecretRules"
        data-cy="tf-password"
      >
        <template slot="append">
          <v-btn icon @click="createRandomSecret()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-row>
  </div>
</template>

<script>
import { uniqueId } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { clientSecretRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return { integrationSecret: "" };
      },
    },
  },
  computed: {
    clientSecretRules() {
      return clientSecretRules();
    },
  },
  methods: {
    createRandomSecret() {
      this.value.integrationSecret = uniqueId();
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
