<template>
  <BaseContentLoader :title="$t('_UserManagement')" :isLoading="isLoading">
    <template slot="content">
      <v-row>
        <v-col cols="4">
          <v-treeview
            :active.sync="selectedItems"
            :items="treeItems"
            activatable
            hoverable
            open-all
            return-object
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.class === 'organizations'">
                mdi-office-building
              </v-icon>
              <v-icon v-else-if="item.type === 'SLS'">
                mdi-lightbulb-on-outline
              </v-icon>
              <v-icon v-else-if="item.type === 'STS'"> mdi-highway </v-icon>
              <v-icon v-else-if="item.type === 'SVS'"> mdi-pipe-valve </v-icon>
              <v-icon v-else-if="item.type === 'SES'"> mdi-leaf </v-icon>
            </template>
          </v-treeview>
        </v-col>
        <template>
          <v-divider vertical></v-divider>
          <v-col>
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col
                  ><template v-if="selectedItems.length === 1">
                    <strong>{{ groupName }}</strong>
                    <span> ({{ selectedItems[0].id }})</span>
                    <BaseTable
                      :headers="tableHeaders"
                      :items="tableItems"
                      @canEdit="(user) => updateUser(user)"
                      @canRemove="(user) => removeUserFromGroup(user)"
                      itemName="username"
                    >
                      <template v-slot:[`item.roles`]="{ item }">
                        <v-chip-group multiple :value="item.roles" column>
                          <v-chip
                            v-for="role in item.roles"
                            :key="role"
                            :value="role"
                            outlined
                          >
                            {{ role }}</v-chip
                          >
                        </v-chip-group>
                      </template>

                      <template v-slot:[`edit-dialog-content`]="{ item }">
                        <v-row align="center">
                          <v-col cols="4">{{
                            $t("_Roles") + " (" + groupName + "):"
                          }}</v-col>
                          <v-col cols="8">
                            <v-chip-group multiple v-model="item.roles" column>
                              <v-chip
                                v-for="role in getAllRoles"
                                :key="role"
                                :value="role"
                                outlined
                                filter
                                :disabled="role === 'VIEWER'"
                              >
                                {{ role }}</v-chip
                              >
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </template>
                    </BaseTable>

                    <v-row class="pt-8">
                      <v-spacer></v-spacer>

                      <BaseDialogButton
                        :dialogTitle="$t('_InviteUser')"
                        :buttonText="$t('_InviteUser')"
                        :confirmButtonText="$t('_InviteUser')"
                        buttonIcon="mdi-account-plus"
                        :disabled="disabledInvite"
                        width="450"
                        @confirm="inviteToGroup()"
                      >
                        <template slot="content">
                          <v-form ref="form" v-model="isValid">
                            <v-text-field
                              v-model="username"
                              :label="$t('_Username') + ' *'"
                              :rules="usernameRules"
                              data-cy="tf-username"
                            ></v-text-field>
                          </v-form>
                        </template>
                      </BaseDialogButton>
                    </v-row>
                  </template>
                  <p v-else class="text-center">
                    {{ $t("__CFA1_EditUserRole") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </template>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import {
  delay,
  sortByKey,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { usernameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";

export default {
  data() {
    return {
      isLoading: true,
      selectedItems: [],
      username: "",
      isValid: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadServices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getServices",
      "getAllGrants",
      "getAllRoles",
      "getCurrentUser",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    group() {
      if (this.selectedItems === undefined) return;
      if (this.selectedItems[0] === undefined) return;
      return [this.selectedItems[0].class, this.selectedItems[0].id].join("/");
    },
    groupName() {
      if (this.group === undefined) return;
      return this.selectedItems[0].name;
    },
    services() {
      var that = this;
      return this.getServices
        .filter((s) => s.organizationId === that.getCurrentOrganization.id)
        .map((s) => {
          let obj = Object.assign({}, s);
          obj.class = "services";
          return obj;
        });
    },
    userIds() {
      if (this.group === undefined) return [];
      return [...new Set(this.getAllGrants.map((g) => g.id))];
    },
    tableItems() {
      if (this.group === undefined) return [];
      var that = this;
      return this.userIds
        .map((userId) => {
          var obj = Object.assign({}, { id: userId });
          obj.roles = [
            ...new Set(
              that.getAllGrants
                .filter((g) => g.id === userId)
                .map((g) => g.role)
                .sort(),
            ),
          ];
          obj.username = that.getAllGrants.find(
            (g) => g.id === userId,
          ).username;
          obj.editable = true;
          obj.removable = true;
          obj.props = {
            disabled:
              that.getCurrentUser === obj.username ||
              (!this.isSystemAdmin && !this.isCurrentOrganizationsAdmin),
          };
          return obj;
        })
        .sort((a, b) => sortByKey(b, a, "username"));
    },
    treeItems() {
      return [
        {
          id: this.getCurrentOrganization.id,
          name: this.getCurrentOrganization.name,
          class: "organizations",
          children: this.services,
        },
      ];
    },
    tableHeaders() {
      return [
        { text: this.$t("_Username").toUpperCase(), value: "username" },
        { text: this.$t("_Roles").toUpperCase(), value: "roles" },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
    disabledInvite() {
      if (this.group === undefined) return true;
      return !(
        (this.isSystemAdmin || this.isCurrentOrganizationsAdmin) // This is when the user has selected a services
      );
    },
    usernameRules() {
      return usernameRules();
    },
  },
  methods: {
    async inviteToGroup() {
      // You can return if the user is already a member of the group
      if (this.tableItems.map((u) => u.username).includes(this.username)) {
        this.username = "";
        return;
      }

      this.isLoading = true;
      try {
        const payload = {
          user: { username: this.username },
          group: this.group,
          role: "VIEWER",
        };
        await delay(500);
        await this.$store.dispatch("addUserToGroup", payload);
      } catch (error) {
        console.log(error);
      }
      this.username = "";
      this.isLoading = false;
    },

    async removeUserFromGroup(user) {
      var that = this;
      this.isLoading = true;
      try {
        await delay(500);
        user.roles.forEach(async function (role) {
          var payload = {
            user: user,
            group: that.group,
            role: role,
          };
          await delay(500);
          await that.$store.dispatch("removeUserFromGroup", payload);
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async updateUser(user) {
      this.isLoading = true;
      try {
        await delay(500);
        var oldRoles = Object.assign(
          {},
          this.tableItems.find((u) => u.id === user.id),
        ).roles;
        var newRoles = user.roles;

        // This is because there might be some roles added
        let addRoles = newRoles.filter((x) => !oldRoles.includes(x));
        for (let index = 0; index < addRoles.length; index++) {
          await this.$store.dispatch("addUserToGroup", {
            user: user,
            group: this.group,
            role: addRoles[index],
          });
        }

        // This is because there might be some roles removed
        let removeRoles = oldRoles.filter((x) => !newRoles.includes(x));
        for (let index = 0; index < removeRoles.length; index++) {
          await this.$store.dispatch("removeUserFromGroup", {
            user: user,
            group: this.group,
            role: removeRoles[index],
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    selectedItems: async function () {
      if (this.group === undefined) return;

      this.isLoading = true;
      try {
        await this.$store.dispatch("loadGroupRoles", this.group);
        await this.$store.dispatch("loadGroupGrants", this.group);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
