<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newService"
          :items="summaryItems"
          isEditable
        ></BaseSummary>
      </v-form>

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}</v-btn
        >
        <v-btn
          class="mx-3"
          color="primary"
          @click="saveChanges()"
          :disabled="disabledSave"
          data-cy="btn-save"
          >{{ $t("_SaveChanges") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";

import { cloneDeep, isEqual } from "lodash";

export default {
  data() {
    return {
      isLoading: true,
      isValid: true,
      newService: undefined,
    };
  },

  async mounted() {
    try {
      this.newService = cloneDeep(
        await this.$store.dispatch("loadService", this.$route.query.id),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getServiceById",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
    ]),
    title() {
      if (this.service == undefined) return "";
      return this.$t("_Service") + ": " + this.service.name;
    },
    service() {
      return this.getServiceById(this.$route.query.id);
    },
    disabledSave() {
      if (!this.isValid) return true;
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newService == undefined) return true;
      return isEqual(this.newService, this.service);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newService == undefined) return true;
      return isEqual(this.newService, this.service);
    },
    summaryItems() {
      return [
        {
          key: "id",
          name: "ID",
          type: "text-field",
          props: {
            disabled: true,
          },
        },
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            rules: nameRules(),
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "type",
          name: this.$t("_Type"),
          type: "text-field",
          props: { disabled: true },
        },
        {
          key: "isEnabled",
          name: this.$t("_Activated"),
          type: "checkbox",
          props: { disabled: true },
        },
      ];
    },
  },
  methods: {
    undoChanges() {
      this.newService = cloneDeep(this.service);
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("updateService", this.newService);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
