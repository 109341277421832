<template>
  <div data-cy="add-lorawan-device-group">
    <v-text-field
      v-model="value.mcDevEUI"
      :label="$t('_MulticastDevEUI') + ' *'"
      :rules="mcDevEUIRules"
      data-cy="tf-mc-dev-eui"
      counter="16"
    >
      <template slot="append">
        <v-btn icon @click="randomMcDevEUI()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-text-field
      v-model="value.nwAdr"
      :label="$t('_MulticastAddress') + ' *'"
      :rules="nwAdrRules"
      data-cy="tf-nw-adr"
      counter="8"
    >
      <template slot="append">
        <v-btn icon @click="randomNwAdr()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
    <v-text-field
      v-model="value.nwSKey"
      :label="$t('_MulticastNetworkSessionKey') + ' *'"
      :rules="nwSKeyRules"
      data-cy="tf-nw-s-key"
      counter="32"
    >
      <template slot="append">
        <v-btn icon @click="randomNwSKey()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
    <v-text-field
      v-model="value.appSKey"
      :label="$t('_MulticastApplicationSessionKey') + ' *'"
      :rules="appSKeyRules"
      data-cy="tf-app-s-key"
      counter="32"
    >
      <template slot="append">
        <v-btn icon @click="randomAppSKey()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template></v-text-field
    >
    <v-text-field
      v-model="value.connectivityPlanId"
      label="Connectivity Plan ID *"
      :rules="nameRules"
      data-cy="tf-connectivity-plan-id"
    ></v-text-field>
    <v-text-field
      v-model="value.routingProfileId"
      label="Routing Profile ID *"
      :rules="nameRules"
      data-cy="tf-routing-profile-id"
    ></v-text-field>
    <v-text-field
      v-model="value.tag"
      label="Tag"
      data-cy="tf-tag"
    ></v-text-field>
  </div>
</template>

<script>
import { genHexString } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  mcDevEUIRules,
  nwAdrRules,
  nwSKeyRules,
  appSKeyRules,
  nameRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return { mcDevEUI: "", nwAdr: "", nwSKey: "", appSKey: "" };
      },
    },
  },
  computed: {
    mcDevEUIRules() {
      return mcDevEUIRules();
    },
    nwAdrRules() {
      return nwAdrRules();
    },
    nwSKeyRules() {
      return nwSKeyRules();
    },
    appSKeyRules() {
      return appSKeyRules();
    },
    nameRules() {
      return nameRules();
    },
  },
  methods: {
    randomMcDevEUI() {
      this.value.mcDevEUI = genHexString(16).toUpperCase();
      this.$emit("input", this.value);
    },
    randomNwAdr() {
      this.value.nwAdr = genHexString(8).toUpperCase();
      this.$emit("input", this.value);
    },
    randomNwSKey() {
      this.value.nwSKey = genHexString(32).toUpperCase();
      this.$emit("input", this.value);
    },
    randomAppSKey() {
      this.value.appSKey = genHexString(32).toUpperCase();
      this.$emit("input", this.value);
    },
  },
  watch: {
    value: {
      handler() {
        this.value.mcDevEUI = this.value.mcDevEUI.toUpperCase();
        this.value.nwAdr = this.value.nwAdr.toUpperCase();
        this.value.nwSKey = this.value.nwSKey.toUpperCase();
        this.value.appSKey = this.value.appSKey.toUpperCase();

        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
