import Base from "@/data/api/Base";

export default {
  createDeviceGroup(deviceGroup) {
    return Base(true).http.post("/device-groups", deviceGroup);
  },
  listDeviceGroups() {
    return Base(true).http.get("/device-groups?size=" + Base(false).maxSize);
  },
  getDeviceGroup(deviceGroupId) {
    return Base(true).http.get("/device-groups/" + deviceGroupId);
  },
  updateDeviceGroup(deviceGroup) {
    return Base(true).http.patch(
      "/device-groups/" + deviceGroup.id,
      deviceGroup,
    );
  },
  deleteDeviceGroup(deviceGroup) {
    return Base(true).http.delete("/device-groups/" + deviceGroup.id);
  },
  sendMessage(deviceGroup, message) {
    return Base(true).http.post(
      "/device-groups/" + deviceGroup.id + "/send",
      message,
    );
  },
  sendCommand(deviceGroup, downlink) {
    return Base(true).http.post(
      "/device-groups/" + deviceGroup.id + "/command",
      downlink,
    );
  },
};
