<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newSharing"
          :items="summaryItems"
          isEditable
        ></BaseSummary>
        <v-card-subtitle>HTTP Header</v-card-subtitle>
        <BaseDynamicFields
          v-if="newSharing !== undefined && newSharing.protocol === 'HTTP'"
          v-model="newSharing.headers"
        ></BaseDynamicFields>
      </v-form>
      <v-row class="px-3">
        <v-col v-if="newSharing !== undefined">
          <BaseCodeEditor v-model="newSharing.script" />
        </v-col>
      </v-row>
      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}</v-btn
        >
        <v-btn
          class="mx-3"
          color="primary"
          @click="save"
          :disabled="disabledSave"
          data-cy="btn-save"
          >{{ $t("_SaveChanges") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import BaseCodeEditor from "@/components/BaseCodeEditor.vue";
import {
  nameRules,
  usernameRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import {
  delay,
  isDifferent,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import BaseDynamicFields from "@/components/BaseDynamicFields.vue";

export default {
  components: {
    BaseDynamicFields,
    BaseCodeEditor,
  },
  data() {
    return {
      isLoading: true,
      isValid: false,
      newSharing: undefined,
      executionSuccess: true,
      showConfirmDialog: false,
      confirmDialogActions: [
        {
          buttonText: "Confirm",
          color: "error white--text",
          disabled: false,
          method: () => {
            this.showConfirmDialog = false;
            this.saveChanges();
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.newSharing = cloneDeep(
        await this.$store.dispatch("loadSharingWithId", this.$route.query.id),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getSharingById",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
    ]),
    summaryItems() {
      if (this.newSharing === undefined) return [];

      let items = [
        {
          key: "id",
          name: "ID",
          type: "text-field",
          props: {
            readonly: true,
          },
        },
        {
          key: "deviceTypeString",
          name: this.$t("_DeviceType"),
          type: "text-field",
          props: {
            readonly: true,
          },
        },
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            rules: nameRules(),
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "error",
          name: this.$t("_Deactivated"),
          type: "select",
          props: {
            items: [true, false],
          },
        },
        {
          key: "protocol",
          name: this.$t("_Protocol"),
          type: "select",
          props: {
            items: ["HTTP", "MQTT"],
            disabled: true,
          },
        },
        {
          key: "address",
          name: this.$t("_TargetAddress"),
          type: "text-field",
        },
      ];

      // Add relevant Fields depending on Sharing is HTTP or MQTT
      if (this.newSharing !== null && this.newSharing.protocol === "MQTT") {
        items.push(
          {
            key: "mqttConfig.topic",
            name: "MQTT Topic",
            type: "text-field",
          },
          {
            key: "mqttConfig.username",
            name: this.$t("_Username"),
            type: "text-field",
            props: {
              rules: usernameRules(),
            },
          },
          {
            key: "mqttConfig.password",
            name: this.$t("_Password"),
            type: "text-field",
            props: {
              type: "password",
            },
          },
        );
      }
      return items;
    },
    title() {
      if (this.currentSharing === undefined) return;
      return this.$t("_DataSharing") + ": " + this.currentSharing.name;
    },
    currentSharing() {
      return this.getSharingById(this.$route.query.id);
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newSharing === undefined) return true;
      if (!this.isValid) return true;
      if (Object.keys(this.newSharing.headers).includes("")) {
        return true;
      }
      var keys = this.summaryItems.map((si) => si.key);
      keys.push("headers");
      return !isDifferent(this.newSharing, this.currentSharing, keys);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newSharing === undefined) return true;

      return isEqual(this.newSharing, this.currentSharing);
    },
  },
  methods: {
    undoChanges() {
      this.isLoading = true;
      this.newSharing = cloneDeep(this.currentSharing);
      this.$refs.form.resetValidation();
      this.isLoading = false;
    },
    save() {
      if (!this.executionSuccess) this.showConfirmDialog = true;
      else this.saveChanges();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("saveSharing", this.newSharing);
        await delay(500);
        this.newSharing = cloneDeep(this.currentSharing);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    "newSharing.script": function (newValue, oldValue) {
      if (oldValue === undefined) return;
      if (newValue === this.currentSharing.script) return;
      this.executionSuccess = false;
    },
  },
};
</script>
