<template>
  <v-row justify="center">
    <BaseTile :title="$t('_Integrations')" path="/dad/integrations" />
    <BaseTile :title="$t('_Devices')" path="/dad/devices" />
    <BaseTile :title="$t('_DeviceGroups')" path="/dad/deviceGroups" />
    <BaseTile :title="$t('_DataSharings')" path="/dad/datasharing" />
  </v-row>
</template>
<script>
export default {};
</script>
