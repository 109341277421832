import Base from "@/data/api/Base";

export default {
  // Create
  createSharing(payload) {
    return Base(true).http.post("/sharing", payload);
  },

  // Read
  listSharing() {
    return Base(true)
      .http.get("/sharing")
      .then((r) => {
        r.data.result.forEach(
          (s) =>
            (s.deviceTypeString =
              s.deviceDetails.vendor +
              "/" +
              s.deviceDetails.deviceType +
              "/" +
              s.deviceDetails.firmware),
        );
        return r;
      });
  },
  getSharing(id) {
    return Base(true)
      .http.get("/sharing/" + id)
      .then((r) => {
        if (r.data.result) {
          var result = r.data.result;
        }
        result.deviceTypeString =
          result.deviceDetails.vendor +
          "/" +
          result.deviceDetails.deviceType +
          "/" +
          result.deviceDetails.firmware;

        return r;
      });
  },

  // Update
  updateSharing(payload) {
    return Base(true).http.patch("/sharing", payload);
  },

  // Delete
  deleteSharing(id) {
    return Base(true).http.delete("/sharing/" + id);
  },

  // Helper
  tryExecuteCode(code) {
    return Base(true).http.post("/sharing/dryrun", code);
  },
};
