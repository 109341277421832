import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import i18n from "./plugins/i18n";
import Keycloak from "keycloak-js";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import router from "./router/router";

// Frequently used components
const requireComponent = require.context(
  "@smart-city-plattform/vue-component-library/src/components/",
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, ""),
    ),
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

let initOptions;

if (process.env.NODE_ENV === "development") {
  initOptions = {
    url: `${process.env.VUE_APP_KEYCLOAK_URL}` + "/auth",
    realm: `${process.env.VUE_APP_REALM_NAME}`,
    clientId: "grovez-frontend",
    onLoad: "login-required",
  };
} else {
  initOptions = {
    url: "/auth",
    realm: `${process.env.VUE_APP_REALM_NAME}`,
    clientId: "grovez-frontend",
    onLoad: "login-required",
  };
}

const keycloak = Keycloak(initOptions);

Vue.prototype.keycloak = keycloak;

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.log("Authenticated");

      keycloak
        .loadUserProfile()
        .then((profile) =>
          localStorage.setItem("userProfile", JSON.stringify(profile)),
        );

      localStorage.setItem("pxce-token", keycloak.token);

      new Vue({
        vuetify,
        router,
        store,
        i18n,
        render: (h) => h(App),
      }).$mount("#app");

      setInterval(() => {
        keycloak
          .updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token refreshed " + refreshed);
              localStorage.setItem("pxce-token", keycloak.token);
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            keycloak.logout();
          });
      }, 60000);
    }
  })
  .catch(() => {
    console.error("Authenticated Failed");
    keycloak.logout();
  });
