<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        :confirmButtonText="$t('_Save')"
        @confirm="editDevicesActivation()"
        @cancel="$router.push('/dad/devices')"
      >
        <template slot="tab-item-0">
          <v-col>
            <v-radio-group v-model="activationOption">
              <div
                align="center"
                v-for="(option, index) in activationOptions"
                :key="index"
              >
                <v-col>
                  <v-radio
                    :disabled="option.disabled"
                    :label="option.name"
                    :value="option.value"
                  >
                  </v-radio>
                </v-col>
              </div>
            </v-radio-group>
          </v-col>
        </template>
        <template slot="tab-item-1">
          <v-col>
            <BaseTable
              v-model="selectedItems"
              :headers="tableHeaders"
              :items="tableItems"
              :rules="tableRules"
              showSelect
            >
              <template v-slot:[`item.isEnabled`]="{ item }">
                <v-icon v-if="item.isEnabled" color="primary">mdi-check</v-icon>
                <v-icon v-else color="error">mdi-cancel</v-icon>
              </template>
            </BaseTable>
          </v-col>
        </template>
        <template slot="tab-item-2">
          <v-col v-if="activationOption === 'BY_LICENCE'">
            <v-col>
              <v-row
                v-for="(lic, index) in numberOfLicences"
                :key="index"
                align="baseline"
              >
                <v-col cols="3" class="px-2 text-center">
                  {{ lic.type }}:
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    class="px-2"
                    :value="lic.requested"
                    readonly
                    type="number"
                    :label="$t('_RequestedLicences')"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    class="px-2"
                    :value="lic.active"
                    readonly
                    type="number"
                    :label="$t('_LicencesInUse')"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    class="px-2"
                    :value="lic.licences"
                    type="number"
                    readonly
                    :label="$t('_AvailableLicences')"
                    :rules="enoughLicencesRules(lic.requested, lic.active)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { tableRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  data() {
    return {
      isLoading: true,
      activationOption: undefined,
      selectedItems: [],
    };
  },
  async mounted() {
    try {
      this.activationOption = this.activationOptions[0].value;
      await this.$store.dispatch("loadDevices");
      await this.$store.dispatch("loadLicenceBundle");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getAllDevices",
      "getLicenceBundle",
    ]),
    subHeaders() {
      return [
        `${this.$t("__CFA1_EditDevicesActivation")}`,
        `${this.$t("__CFA2_EditDevicesActivation")}`,
        this.activationOption === "BY_LICENCE"
          ? `${this.$t("__CFA3a_EditDevicesActivation")}`
          : this.activationOption === "AS_ADMIN"
            ? `${this.$t("__CFA3b_EditDevicesActivation")}`
            : `${this.$t("__CFA3c_EditDevicesActivation")}`,
      ];
    },
    tabHeaders() {
      return [
        this.$t("_SelectActivationOption"),
        this.$t("_SelectDevices"),
        this.$t("_Confirm"),
      ];
    },
    activationOptions() {
      return [
        {
          name: this.$t("_ActivateWithLicence"),
          value: "BY_LICENCE",
        },
        {
          name: this.$t("_ActivateAsAdmin"),
          value: "AS_ADMIN",
          disabled: !this.isSystemAdmin,
        },
        { name: this.$t("_NoActivation"), value: "NO_ACTIVATION" },
      ];
    },
    devices() {
      return this.getAllDevices;
    },
    tableItems() {
      return this.getAllDevices.filter(
        (s) =>
          (this.activationOption === "BY_LICENCE" && !s.isEnabled) ||
          (this.activationOption === "AS_ADMIN" && !s.isEnabled) ||
          (this.activationOption === "NO_ACTIVATION" && s.isEnabled),
      );
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
        },
        {
          text: this.$t("_DeviceId").toUpperCase(),
          value: "deviceId",
        },
        {
          text: this.$t("_Tag").toUpperCase(),
          value: "tag",
        },
        {
          text: this.$t("_Activated").toUpperCase(),
          value: "isEnabled",
        },
      ];
    },
    tableRules() {
      return tableRules();
    },
    selectedTags() {
      if (this.selectedItems.length === 0) {
        return [];
      }
      return [...new Set(this.selectedItems.map((e) => e.tag))];
    },
    numberOfLicences() {
      if (this.selectedTags == null) {
        return;
      }

      return this.selectedTags.map((tag) => {
        let licenceObj = {
          type: tag,
          requested: this.selectedItems.filter((a) => a.tag === tag).length,
          active: this.activeDevices.filter((a) => a.tag === tag).length,
        };

        if (this.getLicenceBundle.licences == null) {
          licenceObj.licences = 0;
          return licenceObj;
        }

        switch (tag) {
          case "lighting":
            licenceObj.licences =
              this.getLicenceBundle.licences["1386962"] != null
                ? this.getLicenceBundle.licences["1386962"]
                : 0;
            break;
          case "light-segment":
            licenceObj.licences =
              this.getLicenceBundle.licences["1386964"] != null
                ? this.getLicenceBundle.licences["1386964"]
                : 0;
            break;
          default:
            licenceObj.licences = 0;
            break;
        }
        return licenceObj;
      });
    },
    activeDevices() {
      return this.getAllDevices.filter((s) => s.isEnabled === true);
    },
  },
  methods: {
    enoughLicencesRules(requested, active) {
      return [(v) => v >= requested + active || "Not enough licences"];
    },
    async editDevicesActivation() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("activateDevices", {
          devices: this.selectedItems,
          activationOption: this.activationOption,
        });
        await this.$router.push("/dad/devices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
