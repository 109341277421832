<template>
  <BaseContentLoader :title="$t('_DataSharings')" :isLoading="isLoading">
    <template v-slot:content>
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        path="/dad/datasharing/sharing?id="
        @canRemove="(item) => removeItem(item)"
      />

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/dad/datasharing/add')"
          >{{ $t("_AddDataSharing") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      tableHeaders: [
        {
          text: this.$t("_Name"),
          value: "name",
        },
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$t("_Protocol"),
          value: "protocol",
        },
        {
          text: this.$t("_DeviceType"),
          value: "deviceTypeString",
        },
        {
          text: this.$t("_TargetAddress"),
          value: "address",
        },
        {
          text: this.$t("_Deactivated"),
          value: "error",
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadSharings");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    tableItems() {
      return this.getAllSharings().map((sharing) => {
        sharing.removable = true;
        return sharing;
      });
    },
  },
  methods: {
    ...mapGetters(["getAllSharings"]),

    async removeItem(sharing) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("removeSharing", sharing);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
