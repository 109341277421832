<template>
  <div>
    <template v-if="messageType === 'LoRaWAN' || messageType === 'JSON'">
      <LoRaWANCustomCommand
        v-if="messageType === 'LoRaWAN'"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <JSONCustomCommand
        v-if="messageType === 'JSON'"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
    <v-container v-else>
      <p class="text-center">{{ $t("_NotSupported") }}</p>
    </v-container>
  </div>
</template>

<script>
import LoRaWANCustomCommand from "./LoRaWANCustomCommand.vue";
import JSONCustomCommand from "./JSONCustomCommand.vue";

export default {
  components: {
    LoRaWANCustomCommand,
    JSONCustomCommand,
  },
  props: {
    integrationType: {
      type: String,
      required: true,
    },
  },
  computed: {
    messageType() {
      if (this.integrationType == undefined) return;
      // MPDT210068-3211
      switch (this.integrationType) {
        case "SWISSCOM":
        case "CHIRPSTACK":
        case "DIGIMONDO":
          return "LoRaWAN";
        case "MQTT":
          return "JSON";
        default:
          return undefined;
      }
    },
  },
};
</script>

<style></style>
