<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        :confirmButtonText="$t('_Save')"
        @confirm="editServicesActivation()"
        @cancel="$router.push('/organization/services')"
      >
        <template slot="tab-item-0">
          <v-col>
            <v-radio-group v-model="activationOption">
              <div
                align="center"
                v-for="(option, index) in activationOptions"
                :key="index"
              >
                <v-col>
                  <v-radio
                    :disabled="option.disabled"
                    :label="option.name"
                    :value="option.value"
                  >
                  </v-radio>
                </v-col>
              </div>
            </v-radio-group>
          </v-col>
        </template>
        <template slot="tab-item-1">
          <v-col>
            <BaseTable
              v-model="selectedItems"
              :headers="tableHeaders"
              :items="tableItems"
              :rules="tableRules"
              single-select
              showSelect
            >
              <template v-slot:[`item.isEnabled`]="{ item }">
                <v-icon v-if="item.isEnabled" color="primary">mdi-check</v-icon>
                <v-icon v-else color="error">mdi-cancel</v-icon>
              </template>
            </BaseTable>
          </v-col>
        </template>
        <template slot="tab-item-2">
          <v-col v-if="activationOption === 'BY_LICENCE'">
            <v-col cols="4">
              <v-text-field
                v-if="selectedItems.length > 0"
                readonly
                :value="selectedItems[0].type"
                :label="$t('_RequiredLicenceType')"
              ></v-text-field>
              <v-text-field
                :value="numberOfActiveServices"
                readonly
                type="number"
                :label="$t('_LicencesInUse')"
              ></v-text-field>
              <v-text-field
                :value="numberOfLicences"
                readonly
                type="number"
                :label="$t('_AvailableLicences')"
                :rules="enoughLicenceRules"
              >
              </v-text-field>
            </v-col>
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import {
  delay,
  serviceTypes,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nameRules,
  tableRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  data() {
    return {
      isLoading: true,
      name: "",
      serviceType: undefined,
      activationOption: undefined,
      selectedItems: [],
    };
  },
  async mounted() {
    try {
      this.serviceType = this.serviceTypes[0];
      this.activationOption = this.activationOptions[0].value;
      await this.$store.dispatch("loadLicenceBundle");
      await this.$store.dispatch("loadServices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getServices",
      "getLicenceBundle",
    ]),
    subHeaders() {
      let subHeaders = [
        this.$t("__CFA1_EditServiceActivation"),
        this.$t("__CFA2_EditServiceActivation"),
      ];

      switch (this.activationOption) {
        case "BY_LICENCE":
          subHeaders.push(this.$t("__CFA3a_EditServiceActivation"));
          break;
        case "AS_ADMIN":
          subHeaders.push(this.$t("__CFA3b_EditServiceActivation"));
          break;
        case "NO_ACTIVATION":
          subHeaders.push(this.$t("__CFA3c_EditServiceActivation"));
          break;
        default:
          break;
      }

      return subHeaders;
    },
    tabHeaders() {
      return [
        this.$t("_SelectActivationOption"),
        this.$t("_SelectService"),
        this.$t("_Confirm"),
      ];
    },
    activationOptions() {
      return [
        {
          name: this.$t("_ActivateWithLicence"),
          value: "BY_LICENCE",
        },
        {
          name: this.$t("_ActivateAsAdmin"),
          value: "AS_ADMIN",
          disabled: !this.isSystemAdmin,
        },
        { name: this.$t("_NoActivation"), value: "NO_ACTIVATION" },
      ];
    },
    services() {
      return this.getServices.filter(
        (s) => s.organizationId === this.getCurrentOrganization.id,
      );
    },

    tableItems() {
      return this.services.filter(
        (s) =>
          (this.activationOption === "BY_LICENCE" && !s.isEnabled) ||
          (this.activationOption === "AS_ADMIN" && !s.isEnabled) ||
          (this.activationOption === "NO_ACTIVATION" && s.isEnabled),
      );
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
        },
        {
          text: this.$t("_Type").toUpperCase(),
          value: "type",
          columnFilter: true,
        },
        {
          text: this.$t("_Activated").toUpperCase(),
          value: "isEnabled",
        },
      ];
    },
    serviceTypes: () => serviceTypes,
    nameRules() {
      return nameRules();
    },
    tableRules() {
      return tableRules();
    },
    numberOfLicences() {
      if (this.getLicenceBundle == undefined) return 0;
      if (this.selectedItems.length === 0) return 0;
      var count = 0;
      switch (this.selectedItems[0].type) {
        case "SLS":
          count =
            this.getLicenceBundle.licences["1386968"] != undefined
              ? this.getLicenceBundle.licences["1386968"]
              : 0 +
                (this.getLicenceBundle.licences["1386966"] != undefined
                  ? this.getLicenceBundle.licences["1386966"]
                  : 0);
          console.log(count);
          return count;
      }
      return count;
    },
    numberOfActiveServices() {
      if (this.selectedItems.length === 0) return;

      return this.services.filter(
        (s) => s.type == this.selectedItems[0].type && s.isEnabled,
      ).length;
    },
    enoughLicenceRules() {
      return [(v) => v > this.numberOfActiveServices || "Not enough licences"];
    },
  },
  methods: {
    async editServicesActivation() {
      this.isLoading = true;
      try {
        let payload = {
          service: this.selectedItems[0],
          activationOption: this.activationOption,
        };
        await delay(500);
        await this.$store.dispatch("activateService", payload);
        this.$router.push("/organization/services");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
