<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="registerDevices"
        @cancel="$router.push('/dad/devices')"
      >
        <template slot="tab-item-0">
          <v-col cols="6">
            <v-select
              :label="$t('_Integration')"
              v-model="integration"
              item-text="name"
              :items="integrations"
              :rules="integrationRules"
              return-object
              data-cy="tf-name"
            ></v-select>
          </v-col>
        </template>

        <template slot="tab-item-1">
          <v-col cols="6"
            ><v-file-input
              v-model="file"
              :label="$t('_UploadCSVFile')"
              data-cy="file-upload"
              :rules="csvFileRules"
            ></v-file-input>
          </v-col>
          <v-col cols="6"
            ><v-checkbox
              v-model="updateExisting"
              :label="$t('_UpdateExistingDevices')"
              data-cy="update-existing"
              :disabled="disabledUpdateExisting"
            />
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import {
  csvToArray,
  delay,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  integrationRules,
  csvFileRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import IntegrationsApi from "@/data/api/IntegrationsApi";
export default {
  data() {
    return {
      isLoading: true,
      integration: undefined,
      file: undefined,
      updateExisting: false,
      showModal: false,
      deviceRegistrationBulk: {},
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadIntegrations");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getIntegrationsByOrganizationId",
    ]),
    subHeaders() {
      return [
        `${this.$t("__CFA1_RegisterDevices")}`,
        `${this.$t("__CFA2_RegisterDevices")} ${
          this.integration?.name ? ` (${this.integration.name})` : ""
        }`,
      ];
    },
    tabHeaders() {
      return [this.$t("_Integration"), this.$t("_RegistrationFile")];
    },
    integrations() {
      return this.getIntegrationsByOrganizationId(
        this.getCurrentOrganization.id,
      );
    },
    integrationRules() {
      return integrationRules();
    },
    csvFileRules() {
      return csvFileRules();
    },

    disabledUpdateExisting() {
      if (this.integration === undefined) return true;
      return this.integration.type !== "CHIRPSTACK";
    },
  },
  methods: {
    async registerDevices() {
      this.isLoading = true;
      try {
        await delay(500);
        await IntegrationsApi.registerDevices(
          this.deviceRegistrationBulk,
          this.integration.id,
          this.updateExisting,
        );
        await this.$router.push("/dad/devices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    file: function () {
      if (this.file === undefined) return;
      const reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = (e) => {
        const text = e.target.result;
        this.deviceRegistrationBulk.devices = [...csvToArray(text)];
      };
    },
  },
};
</script>
