<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <v-form v-model="isValid" ref="form">
        <BaseSummary
          v-model="newOrganization"
          :items="summaryItems"
          isEditable
        ></BaseSummary>
      </v-form>

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          @click="undoChanges()"
          :disabled="disabledUndo"
          data-cy="btn-undo"
        >
          <v-icon left>mdi-undo</v-icon>
          {{ $t("_UndoChanges") }}</v-btn
        >
        <v-btn
          class="mx-3"
          color="primary"
          @click="saveChanges()"
          :disabled="disabledSave"
          data-cy="btn-save"
          >{{ $t("_SaveChanges") }}</v-btn
        >
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";
import { cloneDeep, isEqual } from "lodash";

export default {
  data() {
    return {
      isLoading: true,
      isValid: true,
      newOrganization: undefined,
    };
  },

  async mounted() {
    try {
      this.newOrganization = cloneDeep(
        await this.$store.dispatch("loadOrganization", this.$route.query.id),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getOrganizationById", "isSystemAdmin"]),
    organization() {
      return this.getOrganizationById(this.$route.query.id);
    },
    title() {
      if (this.organization === undefined) return "";
      return this.$t("_Organization") + ": " + this.organization.name;
    },
    disabledSave() {
      if (!this.isValid) return true;
      if (this.newOrganization === undefined) return true;
      return isEqual(this.newOrganization, this.organization);
    },
    disabledUndo() {
      if (this.newOrganization === undefined) return true;
      return isEqual(this.newOrganization, this.organization);
    },
    summaryItems() {
      return [
        {
          key: "id",
          name: "ID",
          type: "text-field",
          props: {
            disabled: true,
          },
        },
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: { rules: nameRules() },
        },
        {
          key: "billingId",
          name: "Billing-ID",
          type: "text-field",
          props: { rules: nameRules() },
        },
      ];
    },
  },
  methods: {
    undoChanges() {
      this.newOrganization = cloneDeep(this.organization);
      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("updateOrganization", this.newOrganization);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
