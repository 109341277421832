<template>
  <BaseTabDialog
    :subHeaders="subHeaders"
    :tabHeaders="tabHeaders"
    @confirm="addResponsible()"
    @cancel="$router.push('/alarming/responsibles')"
  >
    <template slot="tab-item-0">
      <v-col cols="5">
        <v-text-field
          v-model="name"
          :label="$t('_Name') + ' *'"
          :rules="nameRules"
          data-cy="tf-name"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :label="$t('_Email') + ' *'"
          :rules="emailRules"
          data-cy="tf-email"
        ></v-text-field>
      </v-col>
    </template>
    <template slot="tab-item-1">
      <v-col cols="5">
        <v-card-title class="mx-n4">{{ $t("_Organization") }} </v-card-title>
        <v-chip-group
          v-model="activeOrganizationPlan"
          color="primary"
          multiple
          class="py-2"
        >
          <v-chip
            :key="getCurrentOrganization.id"
            filter
            label
            pill
            :value="getCurrentOrganization.id"
          >
            <template slot="default">{{
              getCurrentOrganization.name
            }}</template>
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-row class="px-3">
        <template v-for="plan in plans">
          <v-col
            v-if="activeOrganizationPlan.includes(plan.id)"
            cols="6"
            :key="plan.id"
          >
            <v-card class="my-2">
              <v-card-title>{{
                `${$t("_ResponsibilityPlan")}: ${plan.name}`
              }}</v-card-title>
              <v-card-text>
                <v-card-subtitle>{{ $t("_ServiceTime") }}</v-card-subtitle>
                <template v-if="plan.time !== undefined">
                  <v-row
                    v-for="day in weekdays"
                    :key="day"
                    class="px-4 mt-n6"
                    align="center"
                  >
                    <v-col cols="4"
                      ><span class="text-right text-subtitle-2">{{
                        day
                      }}</span></v-col
                    >
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].start"
                      ></BaseTimePicker
                    ></v-col>
                    <v-col cols="1">-</v-col>
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].end"
                      ></BaseTimePicker
                    ></v-col>
                  </v-row>
                </template>
                <v-divider class="mt-4 py-4"></v-divider>
                <v-card-subtitle>{{ $t("_AlarmType") }}</v-card-subtitle>
                <v-chip-group
                  v-model="plan.alarmTypes"
                  :label="$t('_Services') + ' *'"
                  color="primary"
                  class="px-4"
                  multiple
                >
                  <v-chip
                    v-for="alarmType in alarmTypes"
                    :key="alarmType"
                    filter
                    pill
                    :value="alarmType"
                  >
                    <template slot="default">{{ alarmType }}</template>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card></v-col
          >
        </template>
      </v-row>

      <v-divider class="mt-6 py-4"></v-divider>

      <v-col cols="5">
        <v-card-title class="mx-n4">{{ $t("_Services") }} </v-card-title>
        <v-chip-group
          v-model="activeServicePlans"
          color="primary"
          multiple
          class="py-2"
        >
          <v-chip
            v-for="service in getServices"
            :key="service.id"
            filter
            label
            pill
            :value="service.id"
          >
            <template slot="default">{{ service.name }}</template>
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-row class="px-3">
        <template v-for="plan in plans">
          <v-col
            v-if="activeServicePlans.includes(plan.id)"
            cols="6"
            :key="plan.id"
          >
            <v-card class="my-2">
              <v-card-title>{{
                `${$t("_ResponsibilityPlan")}: ${plan.name}`
              }}</v-card-title>
              <v-card-text>
                <v-card-subtitle>{{ $t("_ServiceTime") }}</v-card-subtitle>
                <template v-if="plan.time !== undefined">
                  <v-row
                    v-for="day in weekdays"
                    :key="day"
                    class="px-4 mt-n6"
                    align="center"
                  >
                    <v-col cols="4"
                      ><span class="text-right text-subtitle-2">{{
                        day
                      }}</span></v-col
                    >
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].start"
                      ></BaseTimePicker
                    ></v-col>
                    <v-col cols="1">-</v-col>
                    <v-col cols="3"
                      ><BaseTimePicker
                        v-model="plan.time[day.toLowerCase()].end"
                      ></BaseTimePicker
                    ></v-col>
                  </v-row>
                </template>
                <v-divider class="mt-4 py-4"></v-divider>
                <v-card-subtitle>{{ $t("_AlarmType") }}</v-card-subtitle>
                <v-chip-group
                  v-model="plan.alarmTypes"
                  :label="$t('_Services') + ' *'"
                  color="primary"
                  class="px-4"
                  multiple
                >
                  <v-chip
                    v-for="alarmType in alarmTypes"
                    :key="alarmType"
                    filter
                    pill
                    :value="alarmType"
                  >
                    <template slot="default">{{ alarmType }}</template>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card></v-col
          >
        </template>
      </v-row>
    </template>
  </BaseTabDialog>
</template>

<script>
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nameRules,
  emailRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules.js";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      name: "",
      email: "",
      activeOrganizationPlan: [],
      activeServicePlans: [],
      plans: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("loadServices");

      this.plans = this.getServices.map((s) => ({
        id: s.id,
        name: s.name,
        alarmTypes: ["FAILURE"],
        time: moment.weekdays().reduce(function (map, obj) {
          map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
          return map;
        }, {}),
      }));

      this.plans.push({
        id: this.getCurrentOrganization.id,
        name: this.getCurrentOrganization.name,
        alarmTypes: ["FAILURE"],
        time: moment.weekdays().reduce(function (map, obj) {
          map[obj.toLowerCase()] = { start: 0, end: 3600 * 1000 * 24 - 1 };
          return map;
        }, {}),
      });
    } catch (err) {
      console.log(err);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getCurrentOrganization", "getServices"]),
    subHeaders() {
      return [
        `${this.$t("__CFA1_AddResponsible")}`,
        `${this.$t("__CFA2_AddResponsible")}`,
      ];
    },
    tabHeaders() {
      return [
        `${this.$t("_Name")} & ${this.$t("_Email")}`,
        `${this.$t("_ResponsibilityPlan")}`,
      ];
    },
    nameRules: () => nameRules(),
    emailRules: () => emailRules(),
    alarmTypes() {
      return ["FAILURE", "WARNING"];
    },
    weekdays() {
      return Object.values(moment.weekdays());
    },
  },
  methods: {
    async addResponsible() {
      this.isLoading = true;
      try {
        const payload = {
          name: this.name,
          email: this.email,
          organizationId: this.getCurrentOrganization.id,
          plans: this.plans
            .filter(
              (p) =>
                this.activeServicePlans.includes(p.id) ||
                this.activeOrganizationPlan.includes(p.id),
            )
            .map((p) =>
              Object({
                alarmTypes: p.alarmTypes,
                id: p.id,
                time: p.time,
              }),
            ),
        };
        await delay(500);
        await this.$store.dispatch("createResponsible", payload);
        await this.$router.push("/alarming/responsibles");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
