<template>
  <BaseTabDialog
    :subHeaders="subHeaders"
    :tabHeaders="tabHeaders"
    @confirm="addDeviceGroup()"
    @cancel="$router.push('/dad/deviceGroups')"
  >
    <template slot="tab-item-0">
      <v-col cols="6">
        <v-text-field
          v-model="name"
          :label="$t('_Name') + ' *'"
          :rules="nameRules"
        ></v-text-field>
      </v-col>
    </template>

    <template slot="tab-item-1">
      <v-col cols="6">
        <v-select
          v-model="vendor"
          :label="$t('_Vendor') + ' *'"
          :items="vendors"
          :rules="vendorRules"
          data-cy="tf-vendor"
        ></v-select>
        <v-select
          v-model="deviceType"
          :label="$t('_DeviceType') + ' *'"
          :items="deviceTypes"
          :rules="deviceTypeRules"
          data-cy="tf-device-type"
        ></v-select>
        <v-select
          v-model="firmware"
          :label="$t('_Firmware') + ' *'"
          :items="firmwares"
          :rules="firmwareRules"
          data-cy="tf-firmware"
        ></v-select>
      </v-col>
    </template>

    <template slot="tab-item-2">
      <v-col cols="6">
        <v-select
          v-model="integration"
          item-text="name"
          :items="integrations"
          :label="$t('_Integration') + ' *'"
          :rules="integrationRules"
          return-object
          @input="infoList = undefined"
        ></v-select>
        <template v-if="integration != undefined">
          <AddChirpstackDeviceGroup
            v-if="integration.type === 'CHIRPSTACK'"
            v-model="infoList"
          />
          <AddSwisscomDeviceGroup
            v-if="integration.type === 'SWISSCOM'"
            v-model="infoList"
          />
          <!--  MPDT210068-3211 DIGIMONDO -->
          <AddDigimondoDeviceGroup
            v-if="integration.type === 'DIGIMONDO'"
            v-model="infoList"
          />
        </template>
      </v-col>
    </template>

    <template slot="tab-item-3">
      <v-col cols="6"
        ><v-text-field
          v-model="groupId"
          :label="$t('_SubscribedGroup') + ' *'"
          data-cy="tf-group-id"
          :rules="groupIdRules"
        ></v-text-field>
      </v-col>
    </template>
  </BaseTabDialog>
</template>
<script>
import { mapGetters } from "vuex";
import AddChirpstackDeviceGroup from "./add-device-group/AddChirpstackDeviceGroup.vue";
import AddSwisscomDeviceGroup from "./add-device-group/AddSwisscomDeviceGroup.vue";
import AddDigimondoDeviceGroup from "./add-device-group/AddDigimondoDeviceGroup.vue";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nameRules,
  vendorRules,
  deviceTypeRules,
  firmwareRules,
  integrationRules,
  groupIdRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  components: {
    AddChirpstackDeviceGroup,
    AddSwisscomDeviceGroup,
    AddDigimondoDeviceGroup,
  },
  data() {
    return {
      isLoading: true,
      name: "",
      groupId: undefined,
      vendor: undefined,
      deviceType: undefined,
      firmware: undefined,
      integration: undefined,
      infoList: undefined,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadOrganizations");
      await this.$store.dispatch("loadIntegrations");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getAllIntegrations",
      "getCurrentOrganization",
      "getSupportedDeviceTypes",
    ]),
    subHeaders() {
      return [
        `${this.$t("__CFA1_AddDeviceGroup")}`,
        `${this.$t("__CFA2_AddDeviceGroup")} (${this.name})`,
        `${this.$t("__CFA3_AddDeviceGroup")} (${this.name})`,
        `${this.$t("__CFA4_AddDeviceGroup")} (${this.name})`,
      ];
    },
    tabHeaders() {
      return [
        this.$t("_Name"),
        this.$t("_DeviceType"),
        this.$t("_Integration"),
        this.$t("_SubscribedGroup"),
      ];
    },
    nameRules() {
      return nameRules();
    },
    vendorRules() {
      return vendorRules();
    },
    deviceTypeRules() {
      return deviceTypeRules();
    },
    firmwareRules() {
      return firmwareRules();
    },
    integrationRules() {
      return integrationRules();
    },
    groupIdRules() {
      return groupIdRules();
    },

    integrations() {
      return this.getAllIntegrations.filter((i) => i.type !== "REST");
    },
    vendors() {
      return [...new Set(this.getSupportedDeviceTypes.map((t) => t.vendor))];
    },
    deviceTypes() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter((t) => t.vendor === that.vendor)
            .map((t) => t.deviceType),
        ),
      ];
    },
    firmwares() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter(
              (t) =>
                t.vendor == that.vendor && t.deviceType === that.deviceType,
            )
            .map((t) => t.firmware),
        ),
      ];
    },
  },
  methods: {
    async addDeviceGroup() {
      this.isLoading = true;
      try {
        var deviceGroup = {
          name: this.name,
          integrationId: this.integration.id,
          organizationId: this.getCurrentOrganization.id,
          mapping: this.groupId,
          details: {
            vendor: this.vendor,
            deviceType: this.deviceType,
            firmware: this.firmware,
          },
          groupDetails: { infoList: this.infoList },
        };
        await delay(500);
        await this.$store.dispatch("createDeviceGroup", deviceGroup);
        this.$router.push("/dad/deviceGroups");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
