<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-switch
        class="mx-3"
        v-model="inputVal"
        :label="$t('_ExpertMode')"
        :disabled="disabled"
        data-cy="sw-expert-mode"
      ></v-switch>
    </v-row>

    <template v-if="inputVal">
      <v-tabs class="py-8" v-model="tab" right>
        <v-tab v-if="diagnosisData != undefined" href="#1">{{
          $t("_ReceivedMessages")
        }}</v-tab>
        <v-tab
          v-if="
            definedCommands != undefined &&
            integrationType !== 'REST' &&
            integrationType !== 'WEBHOOK_DIGIMONDO'
          "
          href="#2"
          >{{ $t("_WellDefinedCommands") }}</v-tab
        >
        <v-tab
          href="#3"
          v-if="
            integrationType !== 'REST' &&
            integrationType !== 'WEBHOOK_DIGIMONDO'
          "
          >{{ $t("_CustomCommands") }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="diagnosisData != undefined" :key="1" value="1">
          <RESTSendExample
            v-if="integrationType === 'REST'"
            :deviceId="device.deviceId"
            :integrationSecret="integration.details.infoList.integrationSecret"
          />
          <WebhookDigimondoSendExample
            v-if="integrationType === 'WEBHOOK_DIGIMONDO'"
            :deviceId="device.deviceId"
            :integrationSecret="integration.details.infoList.integrationSecret"
          />

          <BaseDiagnosis
            :items="diagnosisData"
            @refresh="$emit('refreshDiagnosisData')"
          />
        </v-tab-item>
        <v-tab-item v-if="definedCommands != undefined" :key="2" value="2">
          <BaseDefinedCommands
            :definedCommands="definedCommands"
            :integrationType="integrationType"
            @send="(command) => $emit('sendDefinedCommand', command)"
          />
        </v-tab-item>
        <v-tab-item :key="3" value="3">
          <BaseCustomCommands
            :integrationType="integrationType"
            @send="(command) => $emit('sendCustomCommand', command)"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import BaseCustomCommands from "./BaseCustomCommands.vue";
import BaseDefinedCommands from "./BaseDefinedCommands.vue";
import BaseDiagnosis from "./BaseDiagnosis.vue";
import RESTSendExample from "./RESTSendExample.vue";
import WebhookDigimondoSendExample from "@/components/WebhookDigimondoSendExample";
export default {
  components: {
    BaseDefinedCommands,
    BaseCustomCommands,
    BaseDiagnosis,
    RESTSendExample,
    WebhookDigimondoSendExample,
  },
  data() {
    return {
      tab: undefined,
    };
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    definedCommands: {
      type: Object,
    },
    diagnosisData: {
      type: Array,
    },
    integration: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    device: {
      type: Object,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    integrationType() {
      if (this.integration == undefined) return;
      return this.integration.type;
    },
  },
};
</script>

<style></style>
