<template>
  <BaseContentLoader :title="$t('_Organizations')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        @canRemove="(item) => removeItem(item)"
        path="/system/organizations/edit?id="
      />

      <v-row class="pt-8">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          @click="$router.push('/system/organizations/add')"
          data-cy="btn-add-organization"
        >
          <v-icon left> mdi-plus-thick </v-icon>
          {{ $t("_AddOrganization") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadOrganizations");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getOrganizations", "isSystemAdmin"]),
    tableItems() {
      return this.getOrganizations.map((a) => {
        var obj = Object.assign({}, a);
        obj.removable = true;
        obj.props = { disabled: !this.isSystemAdmin };
        return obj;
      });
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
        },
        {
          text: "Billing ID".toUpperCase(),
          value: "billingId",
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
  },
  methods: {
    async removeItem(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("removeOrganization", item);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
