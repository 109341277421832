import BaseSettings from "@/views/services/settings/BaseSettings.vue";
import i18n from "@/plugins/i18n";

export default {
  settings: {
    path: "/settings",
    name: "Settings",
    component: BaseSettings,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Settings"),
        },
      ],
    },
  },
};
