<template>
  <v-form class="px-2 py-2" ref="form" v-model="isValid">
    <v-textarea
      :label="$t('_JSONString') + ' *'"
      required
      v-model="payload"
      outlined
      :rules="payloadRules"
      data-cy="tf-payload"
    />
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-4 my-6"
        color="primary"
        :disabled="disabledSend"
        depressed
        @click="sendMessage()"
        data-cy="btn-send"
      >
        {{ $t("_SendMessage") }}
        <v-icon v-if="!isLoading" right>mdi-send</v-icon>
        <v-progress-circular
          class="ml-3"
          v-else
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  props: {},
  data() {
    return {
      isLoading: false,
      message: {},
      payload: "",
      isValid: false,
      payloadRules: [
        (v) => !!v || "Payload is required",
        (v) => (v && this.isJSON(v)) || "Payload must be valid JSON",
      ],
    };
  },
  computed: {
    disabledSend() {
      return !this.isValid || this.isLoading;
    },
  },
  methods: {
    async sendMessage() {
      this.isLoading = true;
      this.message.payload = JSON.parse(this.payload);

      await delay(1000);
      this.$emit("send", this.message);

      this.isLoading = false;
      this.payload = "";
      this.$refs.form.resetValidation();
    },
    isJSON(str) {
      try {
        return JSON.parse(str) && !!str;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
