<!-- MPDT210068-6116 - In this component the user has to enter all required
input parameters to establish a connection between our platform and elementIoT
-->
<template>
  <div>
    <v-textarea
      class="py-2"
      v-model="value.apiToken"
      outlined
      label="API-Key *"
      :rules="apiKeyRules"
      @focusout="loadFromElementIot"
    />
    <v-select
      v-model="rootFolder"
      :no-data-text="$t('_APIKeyFirst')"
      :label="'Root-Folder *'"
      :items="rootFolders"
      item-text="name"
      item-value="id"
      data-cy="tf-root-folder"
      :rules="apiKeyRules"
      @focusout="loadFoldersFromElementIot"
    />
    <v-select
      v-model="value.folder"
      :no-data-text="$t('_APIKeyAndRootFirst')"
      :label="'Folder *'"
      :items="folders"
      item-text="name"
      item-value="id"
      data-cy="tf-folder"
      :rules="apiKeyRules"
    />
    <v-select
      v-model="value.driverInstance"
      :no-data-text="$t('_APIKeyFirst')"
      :label="'Driver-Instance *'"
      :items="driverInstances"
      item-text="name"
      item-value="id"
      data-cy="tf-driver-instance"
      :rules="apiKeyRules"
    />
  </div>
</template>

<script>
import {
  urlRules,
  applicationIdRules,
  apiKeyRules,
  organizationIdRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import ElementIot from "@/data/external/ElementIot";

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          apiToken: "",
          url: "",
          applicationId: null,
          organizationId: "",
          folder: "",
          driverInstance: "",
        };
      },
    },
  },
  data() {
    return {
      rootFolder: "",
      rootFolders: [],
      driverInstances: [],
      folders: [],
    };
  },
  computed: {
    urlRules() {
      return urlRules();
    },
    applicationIdRules() {
      return applicationIdRules();
    },
    apiKeyRules() {
      return apiKeyRules();
    },
    rootFolderRules() {
      return apiKeyRules();
    },
    driverInstanceRules() {
      return apiKeyRules();
    },
    organizationIdRules() {
      return organizationIdRules();
    },
  },
  methods: {
    async loadFromElementIot() {
      if (this.value.apiToken !== "" || this.value.apiToken !== null) {
        this.rootFolders = (
          await ElementIot.getRootFolder(this.value.apiToken)
        ).data;
        this.driverInstances = (
          await ElementIot.getDriverInstances(this.value.apiToken)
        ).data;
      }
    },
    async loadFoldersFromElementIot() {
      if (
        this.value.apiToken !== "" ||
        (this.value.apiToken !== null && !this.folders.isEmpty()) ||
        this.folders !== null
      ) {
        this.folders = (
          await ElementIot.getFolder(this.rootFolder, this.value.apiToken)
        ).data;
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
