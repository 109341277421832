<template>
  <BaseContentLoader :title="$t('_AlarmsAndLogs')" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        v-model="selectedItems"
        :headers="tableHeaders"
        :items="tableItems"
        itemName="title"
        :show-select="showAlarmAndLogSelect"
        @canRemove="(item) => deleteAlarm(item)"
        @canCheck="(item) => acknowledgeAlarm(item)"
      >
        <template slot="check-dialog-content">
          {{ $t("__CFA1_AcknowledgeAlarm") }}
        </template>
      </BaseTable>
      <v-row class="pt-8">
        <v-btn
          class="mx-3"
          color="primary"
          @click="exportData()"
          :disabled="disabledExportData"
          data-cy="btn-export-data"
        >
          <v-icon left> mdi-export </v-icon>
          {{ $t("_ExportData") }}
        </v-btn>

        <BaseDialogButton
          class="mx-3"
          color="error"
          :buttonText="$t('_RemoveSelection')"
          :dialogTitle="$t('_RemoveSelection')"
          :confirmButtonText="$t('_Remove')"
          @confirm="deleteSelection()"
          :disabled="disabledDeleteSelection"
          data-cy="btn-remove-alarms"
        >
          <template slot="content">
            {{ $t("__CFA1_DeleteSelection") }}
          </template>
        </BaseDialogButton>

        <v-spacer></v-spacer>
        <v-btn
          class="mx-3"
          color="primary"
          @click="sendTestAlarm()"
          :disabled="disabledSendTestAlarm"
          data-cy="btn-test-alarm"
        >
          <v-icon left> mdi-alarm-light </v-icon>
          {{ $t("_SendTestAlarm") }}
        </v-btn>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  delay,
  exportCSVData,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      selectedItems: [],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadServices");
      await this.$store.dispatch("loadAlarms");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getAllAlarms",
      "getServiceById",
      "getCurrentOrganization",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
      "getLocale",
    ]),
    tableHeaders() {
      return [
        { text: this.$t("_Time").toUpperCase(), value: "time" },
        {
          text: this.$t("_Type").toUpperCase(),
          value: "type",
          columnFilter: true,
        },
        {
          text: this.$t("_Title").toUpperCase(),
          value: "title",
          columnFilter: true,
        },
        { text: this.$t("_Description").toUpperCase(), value: "description" },
        {
          text: this.$t("_Service").toUpperCase(),
          value: "source",
          columnFilter: true,
        },
        {
          text: this.$t("_State").toUpperCase(),
          value: "state",
          columnFilter: true,
        },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    tableItems() {
      return this.getAllAlarms
        .filter((a) => a.organizationId === this.getCurrentOrganization.id)
        .map((a) => {
          var obj = Object.assign({}, a);
          obj.time = moment(obj.timestamp).locale(this.getLocale).format("LLL");
          //obj.removable = true;
          obj.checkable = true;

          let service = this.getServiceById(obj.serviceId);
          obj.source = service != undefined ? service.name : "---";
          obj.props = {
            disabled:
              !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin) ||
              !(
                obj.state === "OPEN" ||
                obj.state === "ACTIVE" ||
                obj.state === "INACTIVE"
              ),
          };
          return obj;
        })
        .sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
    },
    disabledExportData() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledDeleteSelection() {
      if (this.selectedItems.length === 0) return true;
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledSendTestAlarm() {
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    showAlarmAndLogSelect() {
      return this.isCurrentOrganizationsAdmin || this.isSystemAdmin;
    },
  },
  methods: {
    async acknowledgeAlarm(item) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("updateAlarm", {
          id: item.id,
          state:
            item.state === "OPEN" || item.state === "ACTIVE"
              ? "ACKNOWLEDGED"
              : "RESOLVED",
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async deleteSelection() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch(
          "deleteAlarms",
          this.selectedItems.map((i) => i.id),
        );
        this.selectedItems = [];
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async sendTestAlarm() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("createTestAlarm", {
          title: "Test Alarm",
          description: "This is a test alarm to test functionality",
          type: "FAILURE",
          organizationId: this.getCurrentOrganization.id,
          state: "ACTIVE",
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async deleteAlarm(alarm) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("deleteAlarm", alarm);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    exportData() {
      var title = [this.$t("_Alarms"), moment().toLocaleString()].join("-");
      var headers = [
        { value: "time", text: this.$t("_Time") },
        { value: "type", text: this.$t("_Type") },
        { value: "title", text: this.$t("_Title") },
        { value: "description", text: this.$t("_Description") },
        { value: "state", text: this.$t("_State") },
      ];
      exportCSVData(title, [...this.tableItems], true, headers);
    },
  },
};
</script>
