var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-switch',{staticClass:"mx-3",attrs:{"label":_vm.$t('_ExpertMode'),"disabled":_vm.disabled,"data-cy":"sw-expert-mode"},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}})],1),(_vm.inputVal)?[_c('v-tabs',{staticClass:"py-8",attrs:{"right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.diagnosisData != undefined)?_c('v-tab',{attrs:{"href":"#1"}},[_vm._v(_vm._s(_vm.$t("_ReceivedMessages")))]):_vm._e(),(
          _vm.definedCommands != undefined &&
          _vm.integrationType !== 'REST' &&
          _vm.integrationType !== 'WEBHOOK_DIGIMONDO'
        )?_c('v-tab',{attrs:{"href":"#2"}},[_vm._v(_vm._s(_vm.$t("_WellDefinedCommands")))]):_vm._e(),(
          _vm.integrationType !== 'REST' &&
          _vm.integrationType !== 'WEBHOOK_DIGIMONDO'
        )?_c('v-tab',{attrs:{"href":"#3"}},[_vm._v(_vm._s(_vm.$t("_CustomCommands")))]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.diagnosisData != undefined)?_c('v-tab-item',{key:1,attrs:{"value":"1"}},[(_vm.integrationType === 'REST')?_c('RESTSendExample',{attrs:{"deviceId":_vm.device.deviceId,"integrationSecret":_vm.integration.details.infoList.integrationSecret}}):_vm._e(),(_vm.integrationType === 'WEBHOOK_DIGIMONDO')?_c('WebhookDigimondoSendExample',{attrs:{"deviceId":_vm.device.deviceId,"integrationSecret":_vm.integration.details.infoList.integrationSecret}}):_vm._e(),_c('BaseDiagnosis',{attrs:{"items":_vm.diagnosisData},on:{"refresh":function($event){return _vm.$emit('refreshDiagnosisData')}}})],1):_vm._e(),(_vm.definedCommands != undefined)?_c('v-tab-item',{key:2,attrs:{"value":"2"}},[_c('BaseDefinedCommands',{attrs:{"definedCommands":_vm.definedCommands,"integrationType":_vm.integrationType},on:{"send":(command) => _vm.$emit('sendDefinedCommand', command)}})],1):_vm._e(),_c('v-tab-item',{key:3,attrs:{"value":"3"}},[_c('BaseCustomCommands',{attrs:{"integrationType":_vm.integrationType},on:{"send":(command) => _vm.$emit('sendCustomCommand', command)}})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }