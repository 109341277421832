<template lang="html">
  <v-row>
    <CodeEditor
      hide_header
      :read_only="true"
      class="mb-5 mt-0"
      :class="{ 'fix-editor-layout': fixStyle }"
      theme="light"
      font_size="15px"
      height="310px"
      width="100%"
      v-model="messageCode"
    />
    <CodeEditor
      class="mb-5 mt-0"
      :class="{ 'fix-editor-layout': fixStyle }"
      theme="light"
      font_size="15px"
      height="500px"
      min_width="100%"
      v-model="code"
    />
    <v-btn class="mx-3 mb-6" color="primary" @click="tryExecute">
      {{ this.$t("_TryExecution") }}
    </v-btn>

    <BaseInformationModal
      :value="showExecutionDialog"
      :title="$t('_ExecutionSuccess')"
      :content="executionResult"
      @acknowledged="resetDialog"
    />
  </v-row>
</template>

<script>
import CodeEditor from "simple-code-editor";
import DataSharingApi from "@/data/api/SharingApi";
import { mapGetters } from "vuex";

export default {
  components: {
    CodeEditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    protocolDetails: {
      type: Object,
      required: true,
    },
    fixStyle: {
      type: Boolean,
      default: true,
    },
    deviceDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      code: this.value,

      showExecutionDialog: false,
      executionResult: "",
    };
  },
  methods: {
    tryExecute() {
      var that = this;
      var payload = {
        script: this.messageCode + this.code,
      };
      switch (this.protocolDetails.protocol) {
        case "HTTP":
          payload.protocol = "HTTP";
          payload.address = this.protocolDetails.url;
          payload.integrationSecret = this.protocolDetails.secret;
          break;
        case "MQTT":
          payload.protocol = "MQTT";
          payload.username = this.protocolDetails.username;
          payload.password = this.protocolDetails.password;
          break;
      }

      DataSharingApi.tryExecuteCode(payload).then((r) => {
        that.executionResult =
          this.$t("_ExecutionResult") + "\n" + r.data.result;
        that.showExecutionDialog = true;
        that.executionSuccess = true;

        this.$emit("executionSuccess", true);
      });
    },
    resetDialog() {
      this.showExecutionDialog = false;
      this.executionResult = "";
    },
  },
  computed: {
    ...mapGetters(["getCurrentOrganization"]),
    messageCode() {
      return (
        " var message = {\n" +
        '    "mapping": "<ID>",\n' +
        '    "deviceId": "<DEVICE_ID>", \n' +
        '    "organizationId": "' +
        this.getCurrentOrganization.id +
        '",\n' +
        '    "deviceDetails": {\n' +
        '        "vendor": "' +
        this.deviceDetails.vendor +
        '",\n' +
        '        "deviceType": "' +
        this.deviceDetails.deviceType +
        '",\n' +
        '        "firmware": "' +
        this.deviceDetails.firmware +
        '"\n' +
        "    },\n" +
        '    "data": {\n' +
        "    }\n" +
        "}\n"
      );
    },
  },
  watch: {
    value() {
      this.code = this.value;
    },
    code() {
      this.$emit("input", this.code);
    },
  },
};
</script>

<style scoped>
/*This style fixes the codeeditor layout problem, which is introduced by v-card-text*/
.fix-editor-layout {
  line-height: normal;
}
</style>
