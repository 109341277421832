import Base from "@/data/api/Base";

export default {
  // If you have Problems with dev
  // https://medium.com/@wlarch/avoiding-access-control-allow-origin-cors-issues-when-developing-locally-with-chromium-web-e1ea0c9153e3
  // https://superuser.com/questions/716943/how-can-i-open-chrome-from-the-command-line-with-specific-pages-in-tabs-in-mac-o

  // Billing Access Token
  login(username, password) {
    return Base(true, true).http.post("/billing/token", {
      username: username,
      password: password,
    });
  },

  // Billing Account
  getBillingAccount() {
    return Base(true, true, false).http.get("/billing/account");
  },
  updateBillingAccount(accountUpdate) {
    return Base(true, true, false).http.patch(
      "/billing/account",
      accountUpdate,
    );
  },

  // Available Packages
  listAvailablePackages() {
    return Base(true, true, false).http.get("/billing/packages");
  },

  // Subscriptions
  createSubscription(bookingPackage) {
    return Base(true, true, false).http.post(
      "/billing/subscriptions",
      bookingPackage,
    );
  },
  listSubscriptions() {
    return Base(true, true, false).http.get("/billing/subscriptions");
  },
  cancelSubscription(subscription) {
    return Base(true, true, false).http.delete(
      "/billing/subscriptions/" + subscription.id,
    );
  },

  // Licences
  getLicenceBundle() {
    return Base(true, true, false).http.get("/billing/licences");
  },
};
