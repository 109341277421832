<template>
  <BaseContentLoader :title="$t('_PurchaseLicences')" :isLoading="isLoading">
    <template slot="content">
      <BaseProficloudLogin
        v-if="!this.hasValidBillingToken"
      ></BaseProficloudLogin>
      <template v-else>
        <template v-for="(category, ci) in categories">
          <h2 class="py-6" :key="`${ci}-title`">
            {{ category.fields.pageTitle }}
          </h2>
          <v-row class="px-4" :key="`${ci}-row`">
            <template v-for="(variant, vi) in category.fields.planVariants">
              <PackageCard
                v-if="
                  packageItems
                    .map((p) => p.id)
                    .includes(variant.fields.sapNumber)
                "
                :value="
                  packageItems.find((p) => p.id === variant.fields.sapNumber)
                "
                :imagePath="category.fields.heroImage.fields.file.url"
                :key="ci + vi"
              />
            </template>
          </v-row>
          <v-divider class="mt-4 mb-8" :key="`${ci}-div`"></v-divider>
        </template>
        <p>* {{ $t("_WithoutDiscount") }}</p>
        <v-row class="pt-8">
          <v-spacer></v-spacer>
          <BaseDialogButton
            class="mx-3"
            width="400"
            :buttonText="$t('_EditBillingAccount')"
            :dialogTitle="$t('_EditBillingAccount')"
            @confirm="(res) => updateBillingAccount(res)"
          >
            <template v-slot:content>
              <BillingAccount v-model="newBillingAccount" />
            </template>
          </BaseDialogButton>
        </v-row>
      </template>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import BillingAccount from "./BillingAccount.vue";
import PackageCard from "@/components/PackageCard.vue";

import { cloneDeep } from "lodash";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import ContentfulApi from "@/data/external/ContentfulApi";

export default {
  data() {
    return {
      isLoading: true,
      newBillingAccount: {},
      categories: [],
    };
  },
  components: {
    BillingAccount,
    PackageCard,
  },
  async mounted() {
    try {
      let packages = await this.$store.dispatch("loadPackages");
      await this.$store.dispatch("loadSubscriptions");
      this.categories = await ContentfulApi.listItems(packages);

      this.newBillingAccount = cloneDeep(
        await this.$store.dispatch("loadBillingAccount"),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getPackages",
      "getAllSubscriptions",
      "getBillingAccount",
      "hasValidBillingToken",
    ]),
    packageItems() {
      var that = this;
      let licences = {};
      let packageIdsSet = [...new Set(that.getPackages.map((p) => p.id))];

      packageIdsSet.forEach((packageId) => {
        let quantity = 0;
        let subscriptionsByPackageId = that.getAllSubscriptions.filter(
          (s) => s.bookedPackageId === packageId,
        );
        subscriptionsByPackageId.forEach((s) => {
          quantity = quantity + s.quantity;
        });
        licences[packageId] = quantity;
      });

      let packageItems = this.getPackages.map((p) => {
        var obj = { ...p };
        obj.bookedQuantity = licences[p.id];
        return obj;
      });

      packageItems.sort((a, b) => parseInt(b.id) - parseInt(a.id));

      return packageItems;
    },
  },
  methods: {
    async updateBillingAccount() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch(
          "updateBillingAccount",
          this.newBillingAccount,
        );
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
