import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0098A1",
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        pxc_secondary: "#74b248",
        background: "#EBECEC", // Not automatically applied
        background_light: "#F5F6F6",
        disabled_color: "#CCD0D2",
      },
    },
  },
});
