<template>
  <v-form ref="form" v-model="valid">
    <v-text-field
      label="fPort *"
      type="number"
      v-model="message.fPort"
      :rules="fPortRules"
      required
      data-cy="tf-fport"
    />
    <v-text-field
      :label="$t('_HexPayload') + ' *'"
      required
      v-model="message.hexPayload"
      lazy-validation
      :rules="hexPayloadRules"
      data-cy="tf-hex-payload"
    />
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-4 my-6"
        color="primary"
        :disabled="disabledSend"
        depressed
        @click="sendMessage"
        data-cy="btn-send"
      >
        {{ $t("_SendMessage") }}
        <v-icon v-if="!isLoading" right>mdi-send</v-icon>
        <v-progress-circular
          class="ml-3"
          v-else
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: false,
      message: {},
      valid: false,
      fPortRules: [
        (v) => !!v || "fPort is required",
        (v) => (v && v > 0) || "fPort must be greater than 0",
      ],
      hexPayloadRules: [
        (v) => !!v || "Payload is required",
        (v) => (v && v == v.toUpperCase()) || "Payload must be capitalized",
        (v) =>
          (v.match(/[0-9,A-F]/) && v.length % 2 == 0) ||
          "Payload must be valid hex",
      ],
    };
  },
  computed: {
    disabledSend() {
      return !this.valid || this.isLoading;
    },
  },
  methods: {
    async sendMessage() {
      this.isLoading = true;
      await delay(1000);
      this.$emit("send", this.message);
      this.message = {};
      this.isLoading = false;
      this.$refs.form.resetValidation();
    },
    pad(n, l) {
      var s = "" + n;
      while (s.length < l) {
        s = "0" + s;
      }
      return s;
    },
  },
  watch: {
    message: {
      handler: function () {
        this.message.hexPayload = this.message.hexPayload.toUpperCase();
        this.$emit("input", this.message);
      },
      deep: true,
    },
  },
};
</script>
