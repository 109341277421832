import Base from "@/data/api/Base";

export default {
  // Create
  createIntegration(integration) {
    return Base().http.post("/integrations", integration);
  },

  // Read
  listIntegrations() {
    return Base(true).http.get("/integrations?size=" + Base(false).maxSize);
  },
  getIntegration(integrationId) {
    return Base(true).http.get("/integrations/" + integrationId);
  },

  // Update
  updateIntegration(integration) {
    return Base().http.patch("/integrations/" + integration.id, integration);
  },
  registerDevices(deviceBulk, integrationId, updateExistingDevices) {
    return Base().http.post(
      "/integrations/" +
        integrationId +
        "/register?overwrite=" +
        updateExistingDevices,
      deviceBulk,
    );
  },

  // Delete
  deleteIntegration(integration) {
    return Base().http.delete("/integrations/" + integration.id);
  },
};
