import Base from "@/data/api/Base";

export default {
  // Create
  createResponsible(responsible) {
    return Base().http.post("/alarming/responsibles", responsible);
  },

  // Read
  listResponsibles() {
    return Base().http.get("/alarming/responsibles");
  },
  getResponsible(id) {
    return Base().http.get("/alarming/responsibles/" + id);
  },

  // Update
  updateResponsible(responsible) {
    return Base().http.put(
      "/alarming/responsibles/" + responsible.id,
      responsible,
    );
  },

  // Delete
  deleteResponsible(responsible) {
    return Base().http.delete("/alarming/responsibles/" + responsible.id);
  },
};
