<template>
  <v-row justify="center">
    <BaseTile
      data-cy="card-services"
      :title="$t('_Services')"
      path="/organization/services"
    />
    <BaseTile
      data-cy="card-user-management"
      v-if="isCurrentOrganizationsAdmin || isSystemAdmin"
      :title="$t('_UserManagement')"
      path="/organization/users"
    />
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isCurrentOrganizationsAdmin", "isSystemAdmin"]),
  },
};
</script>
