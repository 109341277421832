import OrganizationManagement from "@/views/services/organization/OrganizationManagement.vue";
import OrganizationUsers from "@/views/services/organization/users/OrganizationUsers.vue";
import AllServices from "@/views/services/organization/services/AllServices.vue";
import AddService from "@/views/services/organization/services/AddService.vue";
import BaseService from "@/views/services/organization/services/BaseService.vue";
import EditServiceActivation from "@/views/services/organization/services/EditServiceActivation.vue";
import i18n from "@/plugins/i18n";

export default {
  organizationManagement: {
    path: "/organization",
    name: "organizationManagement",
    component: OrganizationManagement,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
        },
      ],
    },
  },
  services: {
    path: "/organization/services",
    name: "services",
    component: AllServices,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
          href: "/#/organization",
        },
        {
          name: () => i18n.t("_Services"),
        },
      ],
    },
  },
  addService: {
    path: "/organization/services/add",
    name: "addService",
    component: AddService,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
          href: "/#/organization",
        },
        {
          name: () => i18n.t("_Services"),
          href: "/#/organization/services",
        },
        {
          name: () => i18n.t("_AddService"),
        },
      ],
    },
  },
  service: {
    path: "/organization/services/service",
    name: "service",
    component: BaseService,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
          href: "/#/organization",
        },
        {
          name: () => i18n.t("_Services"),
          href: "/#/organization/services",
        },
        {
          name: () => i18n.t("_Service"),
        },
      ],
    },
  },
  editServiceActivation: {
    path: "/organization/services/activation",
    name: "editServiceActivation",
    component: EditServiceActivation,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
          href: "/#/organization",
        },
        {
          name: () => i18n.t("_Services"),
          href: "/#/organization/services",
        },
        {
          name: () => i18n.t("_EditServiceActivation"),
        },
      ],
    },
  },
  userManagement: {
    path: "/organization/users",
    name: "userManagement",
    component: OrganizationUsers,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_OrganizationManagement"),
          href: "/#/organization",
        },
        {
          name: () => i18n.t("_UserManagement"),
        },
      ],
    },
  },
};
