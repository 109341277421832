import Base from "@/data/api/Base";

export default {
  getFolder(id, apiKey) {
    return Base(true).http.get(
      "/element/root-folders/" + id + "?auth=" + apiKey,
    );
  },
  getRootFolder(apiKey) {
    return Base(true).http.get("/element/root-folders?auth=" + apiKey);
  },
  getDriverInstances(apiKey) {
    return Base(true).http.get("/element/driver-instances?auth=" + apiKey);
  },
};
