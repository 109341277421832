<template>
  <BaseContentLoader :title="$t('_BookedLicences')" :isLoading="isLoading">
    <template slot="content">
      <BaseProficloudLogin v-if="!hasValidBillingToken"></BaseProficloudLogin>
      <template v-else>
        <BaseTable
          :headers="tableHeaders"
          :items="tableItems"
          itemName="smartServiceId"
          @canCancel="(item) => cancelSubscription(item)"
        >
          <template slot="unsubscribe-dialog-content">
            {{ $t("__CFACS") }}
          </template>
        </BaseTable>

        <v-row class="pt-8">
          <v-btn
            class="mx-3"
            color="primary"
            @click="exportData()"
            :disabled="disabledExportData"
            data-cy="btn-export-data"
            ><v-icon left>mdi-export</v-icon>
            {{ $t("_ExportData") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </template>
    </template>
  </BaseContentLoader>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  delay,
  exportCSVData,
  sortByKey,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadSubscriptions");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "hasValidBillingToken",
      "getCurrentOrganization",
      "getAllSubscriptions",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getLocale",
    ]),
    tableItems() {
      return this.getAllSubscriptions
        .map((a) => {
          var obj = Object.assign({}, a);
          obj.startDate = moment(a.startDate)
            .locale(this.getLocale)
            .format("LL");
          obj.unsubscribable = true;

          if (moment(a.endDate).isValid()) {
            obj.endDate = moment(a.endDate).locale(this.getLocale).format("LL");
            obj.props = { disabled: true };
          } else {
            obj.endDate = "---";
          }
          return obj;
        })
        .sort((a, b) => sortByKey(b, a, "bookedPackageId"));
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "smartServiceId",
          columnFilter: true,
        },
        {
          text: this.$t("_PackageId").toUpperCase(),
          value: "bookedPackageId",
          columnFilter: true,
        },
        {
          text: this.$t("_InternalOrderId").toUpperCase(),
          value: "internalOrderId",
          columnFilter: true,
        },
        { text: this.$t("_StartDate").toUpperCase(), value: "startDate" },
        { text: this.$t("_EndDate").toUpperCase(), value: "endDate" },
        { text: this.$t("_Quantity").toUpperCase(), value: "quantity" },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledExportData() {
      return false;
    },
  },
  methods: {
    async cancelSubscription(subscription) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("cancelSubscription", subscription);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    exportData() {
      var now = moment().locale(this.getLocale).format("lll");

      var filename = [
        this.$t("_BookedLicences"),
        this.getCurrentOrganization.name,
        now,
      ].join("-");

      var headers = [
        { value: "smartServiceId", text: this.$t("_Name") },
        { value: "bookedPackageId", text: this.$t("PackageId") },
        { value: "internalOrderId", text: this.$t("_InternalOrderId") },
        { value: "startDate", text: this.$t("_StartDate") },
        { value: "endDate", text: this.$t("_EndDate") },
        { value: "quantity", text: this.$t("_Quantity") },
      ];
      exportCSVData(filename, [...this.tableItems], true, headers);
    },
  },
};
</script>
