<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="addOrganization()"
        @cancel="$router.push('/system/organizations')"
      >
        <template slot="tab-item-0">
          <v-col cols="6">
            <v-text-field
              v-model="name"
              :label="$t('_Name') + ' *'"
              :rules="nameRules"
              data-cy="tf-name"
            ></v-text-field>
            <v-text-field
              v-model="billingId"
              label="Billing-ID *"
              :rules="nameRules"
              data-cy="tf-responsible"
            ></v-text-field>
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import { nameRules } from "@smart-city-plattform/vue-component-library/src/helper/rules";

export default {
  data() {
    return {
      isLoading: true,
      name: "",
      billingId: "",
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getCurrentService", "getCurrentOrganization"]),
    subHeaders() {
      return [this.$t("__CFA1_AddOrganization")];
    },
    tabHeaders() {
      return [this.$t("_Name") + " & " + this.$t("_Responsible")];
    },
    nameRules() {
      return nameRules();
    },
  },
  methods: {
    async addOrganization() {
      this.isLoading = true;
      try {
        var payload = {
          name: this.name,
          billingId: this.billingId,
        };
        await delay(500);
        await this.$store.dispatch("createOrganization", payload);
        await this.$router.push("/system/organizations");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
