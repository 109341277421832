<template>
  <div data-cy="add-mqtt-integration">
    <v-text-field
      v-model="value.username"
      :label="$t('_Username') + ' *'"
      :rules="nameRules"
      data-cy="tf-username"
    >
      <template slot="append">
        <v-btn icon @click="createRandomUsername()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-text-field
      v-model="value.password"
      :type="isVisible ? 'text' : 'password'"
      :label="$t('_Password') + ' *'"
      :rules="passwordRules"
      data-cy="tf-password"
    >
      <template slot="append">
        <v-btn icon @click="toggleVisibility()">
          <v-icon v-if="!isVisible">mdi-eye</v-icon>
          <v-icon v-else>mdi-eye-off</v-icon>
        </v-btn>
        <v-btn icon @click="createRandomPassword()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { uniqueId } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  nameRules,
  passwordRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return { clientId: "", password: "", username: "" };
      },
    },
  },
  computed: {
    nameRules() {
      return nameRules();
    },
    passwordRules() {
      return passwordRules();
    },
  },
  methods: {
    createRandomUsername() {
      this.value.username = uniqueId();
    },
    createRandomPassword() {
      this.value.password = uniqueId();
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
  },
  watch: {
    value: {
      handler() {
        this.value.clientId = this.value.username;
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
