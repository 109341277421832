import Base from "@/data/api/Base";

export default {
  // Create
  onboardDevices(deviceBulk) {
    return Base(true).http.post("/devices/onboard", deviceBulk);
  },

  // Read
  listDevices() {
    return Base(true).http.get("/devices?size=" + Base(false).maxSize);
  },
  getDevice(deviceId) {
    return Base(true).http.get("/devices/" + deviceId);
  },
  listAvailableDevices(integrationId) {
    return Base(true).http.get("/devices/unboarded/" + integrationId);
  },
  listSupportedDeviceTypes() {
    return Base(false).http.get("/devices/types");
  },
  getAvailableDeviceCommands(deviceDetails) {
    return Base(false).http.get(
      "/devices/types/commands" +
        "?vendor=" +
        deviceDetails.vendor +
        "&deviceType=" +
        deviceDetails.deviceType +
        "&firmware=" +
        deviceDetails.firmware,
    );
  },
  // MPDT210068-2934
  exportTSD(deviceId, start, end) {
    return Base(true).http.get(
      "/devices/" + deviceId + "/export?start=" + start + "&end=" + end,
    );
  },
  getDeviceDiagnosis(deviceId) {
    return Base(true).http.get("/devices/" + deviceId + "/diagnosis");
  },

  // Update
  updateDevice(device) {
    return Base().http.patch("/devices/" + device.id, device);
  },
  activateDevices(deviceIdList, activationOption) {
    return Base(true).http.put(
      "/devices/activation/" + activationOption,
      deviceIdList,
    );
  },

  // Delete
  deleteDevice(device) {
    return Base(true).http.delete("/devices/" + device.id);
  },

  // Helper
  sendDeviceMessage(device, message) {
    return Base(true).http.post("/devices/" + device.id + "/send", message);
  },
  sendDeviceCommand(device, downlink) {
    return Base(true).http.post("/devices/" + device.id + "/command", downlink);
  },
};
