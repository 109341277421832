<template>
  <!-- MPDT210068-2934: Add Warning that service is not enabled -->
  <div data-cy="base-tenant-select">
    <v-autocomplete
      class="my-2 py-2 centered-input"
      dense
      solo
      flat
      hide-details
      hide-no-data
      hide-selected
      auto-select-first
      :value="value"
      :items="items"
      background-color="primary"
      dark
      return-object
      item-text="name"
      append-icon="mdi-chevron-down"
      :menu-props="{
        maxHeight: 200,
        offsetY: true,
        transition: false,
      }"
      @input="changeOrganization"
    >
      <template slot="prepend-inner">
        <v-icon>mdi-office-building</v-icon>
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-list-item-content :data-cy="'base-tenant-select-item-' + item.name">
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async changeOrganization(newOrganization) {
      await this.$store.dispatch("updateCurrentOrganization", newOrganization);
      await this.$router.push("/");
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}

.centered-input >>> input {
  text-align: center;
  width: min-content;
  font-size: 0.95em;
}
</style>
