<template>
  <div>
    <v-container v-if="definedCommands.commands == null" class="text-center">
      {{ $t("_NotSupported") }}
    </v-container>
    <v-expansion-panels
      v-else
      class="px-4 py-4"
      v-model="expansionPanels"
      focusable
    >
      <v-expansion-panel
        v-for="(item, i, n) in definedCommands.commands"
        :key="i"
      >
        <v-expansion-panel-header>
          {{ i }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form :ref="'cmdForm' + n" v-model="isValid" lazy-isValidation>
            <v-spacer style="height: 1rem"></v-spacer>
            <strong>{{ $t("_Description") + ": " }}</strong>
            <span>{{ item.description }}</span>

            <v-spacer style="height: 1.5rem"></v-spacer>
            <template v-if="item.properties != null">
              <strong v-if="Object.keys(item.properties).length > 0">{{
                $t("_Parameters") + ": "
              }}</strong>
              <v-spacer style="height: 1.5rem"></v-spacer>
              <v-row v-for="(p, j) in item.properties" :key="j">
                <BaseDynamicComponent v-model="values[j]" :item="p" />
              </v-row>
            </template>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-4 my-6"
                color="primary"
                :disabled="disabledSend"
                depressed
                @click="sendMessage()"
                data-cy="btn-send"
              >
                {{ $t("_SendMessage") }}
                <v-icon v-if="!isLoading" right>mdi-send</v-icon>
                <v-progress-circular
                  class="ml-3"
                  v-else
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: false,
      isValid: false,
      expansionPanels: undefined,
      val: {},
    };
  },
  props: {
    definedCommands: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabledSend() {
      if (!this.isValid || this.isLoading) {
        return true;
      }
      return this.disabled;
    },
    intent() {
      if (this.expansionPanels == null) {
        return "";
      }
      return Object.keys(this.definedCommands.commands)[this.expansionPanels];
    },
    values: {
      get: function () {
        return this.val;
      },
      set: function (newValue) {
        this.val = newValue;
      },
    },
  },
  methods: {
    async sendMessage() {
      this.isLoading = true;

      var that = this;
      try {
        var params = {};
        var commandProperties =
          this.definedCommands.commands[this.intent].properties;
        if (commandProperties != null) {
          Object.keys(commandProperties).forEach((key) => {
            if (that.values[key] == null) {
              return;
            }
            params[key] = JSON.parse(JSON.stringify(that.values[key]));
          });
        }

        await delay(1000);
        this.$emit("send", {
          intent: this.intent,
          params: params,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  watch: {
    expansionPanels: function () {
      if (this.expansionPanels != null) {
        if (this.$refs["cmdForm" + this.expansionPanels] == null) return;
        this.$refs["cmdForm" + this.expansionPanels][0].resetValidation();
      }
    },
  },
};
</script>
