import Base from "@/data/api/Base";

export default {
  createTestAlarm(alarm) {
    return Base(true).http.post("/alarming/alarms", alarm);
  },
  listAlarms() {
    return Base(true).http.get("/alarming/alarms");
  },
  getAlarm(id) {
    return Base(true).http.get("/alarming/alarms/" + id);
  },
  updateAlarm(alarm) {
    return Base(true).http.patch("/alarming/alarms/" + alarm.id, alarm);
  },
  deleteAlarm(alarm) {
    return Base(true).http.delete("/alarming/alarms/" + alarm.id);
  },
  deleteAlarms(alarmIds) {
    const payload = { ids: alarmIds };
    return Base(true).http.delete("/alarming/alarms/bulk", {
      data: payload,
    });
  },
};
