import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../i18n/en.json";
import de from "../i18n/de.json";
import it from "../i18n/it.json";

Vue.use(VueI18n);

const messages = {
  en: en,
  de: de,
  it: it,
};

const i18n = new VueI18n({ locale: "en", fallbackLocale: "en", messages });

export default i18n;
