import Base from "@/data/api/Base";

export default {
  // Create
  createService(service) {
    return Base(true).http.post("/services", service);
  },

  // Read
  listServices() {
    return Base(true).http.get("/services?size=" + Base(false).maxSize);
  },
  getService(serviceId) {
    return Base(true).http.get("/services/" + serviceId);
  },

  // Update
  updateService(service) {
    return Base(true).http.patch("/services/" + service.id, service);
  },
  setActivationService(service, activationOption) {
    return Base(true, true).http.put(
      "/services/" + service.id + "/activation/" + activationOption,
    );
  },

  // Delete
  deleteService(service) {
    return Base(true).http.delete("/services/" + service.id);
  },
};
