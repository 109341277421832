import BaseHome from "@/views/services/home/BaseHome.vue";
import i18n from "@/plugins/i18n";

export default {
  home: {
    path: "",
    name: "home",
    component: BaseHome,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_Portal"),
        },
      ],
    },
  },
};
