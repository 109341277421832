const contentful = require("contentful");

const client = contentful.createClient({
  space: "bqwo92p1eklz",
  environment: "master", // defaults to 'master' if not set
  accessToken: "b9DGEbk3YXqGTN8r1DfywC6XqMj-4XfyeLYJUlGcDtI",
});

export default {
  listItems(packages) {
    //return client.getEntries({content_type: 'serviceStore', 'fields.heroImage[exists]': true})
    return (
      client
        .getEntries({
          content_type: "serviceStore",
          "fields.id[in]": packages.map((p) => p.smartServiceId).join(","),
        })
        //return client.getEntries({content_type: 'pricingCards', 'fields.sapNumber[in]': '1490656,1386962,1386964,1386966,1386968'})
        //return client.getEntries()
        .then((response) => response.items)
        .catch(console.error)
    );
  },
};
